import { FormValuesProps } from '@/client/types'

export type FormProps = {
  [key: string]: FormValuesProps
}

export const encodeInfos = (form: FormProps) => {
  const data = {}

  Object.entries(form).forEach(([key, value]) => {
    const currValue = key === 'country_id' ? Number(value?.value) : value?.value
    data[key] = currValue
  })

  return data
}
