import styled, { css } from 'styled-components'
import { StepStylesProps } from './types'
import { Icon } from '../'
import { tokens } from '../theme'
const { borders, breakpoint, color, font, space } = tokens

export const TopBar = styled.div`
  position: sticky;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: ${space.xl5};
  padding: 0 ${space.md};
  border-radius: ${borders.radius.lg} ${borders.radius.lg} 0 0;
  background-color: ${color.primary.darker};
  margin-bottom: -1px;
  z-index: 3;
  @media (min-width: ${breakpoint.xl}) {
    height: ${space.xl4};
  }
`

export const TopCalculatorPrice = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  min-width: 6rem;

  @media (min-width: ${breakpoint.xl}) {
    display: none;
  }
`

export const StepsWrapper = styled.div`
  position: relative;
  width: calc(100% - 6rem);
`

export const Steps = styled.nav`
  position: relative;
  display: flex;
  overflow-x: hidden;
  align-items: center;
  justify-content: flex-start;
  padding-right: ${space.xl4};

  &::-webkit-scrollbar {
    display: none;
  }
`

export const Step = styled.span<StepStylesProps>`
  display: ${({ active, after, before, initial }) => (active || after || before || initial ? 'flex' : 'none')};
  align-items: center;
  justify-content: flex-start;
  height: ${space.xl4};
  padding-left: ${space.md};
  padding-right: ${space.md};
  margin-right: ${space.md};
  color: ${color.text.inverse};
  font-size: ${font.size.root};
  transition: opacity 0.4s ease;
  opacity: ${({ active }) => !active && '0.5'};
  font-weight: ${({ active }) => (active ? font.weight.medium : font.weight.normal)};
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }

  @media (min-width: ${breakpoint.hd}) {
    margin-right: ${space.xl};
  }
`

export const StepNumber = styled.span<StepStylesProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  &:after {
    margin-right: ${space.xs2};

    ${({ finished }) =>
      finished
        ? css`
            content: '';
          `
        : css`
            content: '.';
          `};

    @media (min-width: ${breakpoint.xl}) {
      display: none;
    }
  }

  @media (min-width: ${breakpoint.xl}) {
    min-width: 1.8rem;
    min-height: 1.8rem;
    margin-right: ${space.md};
    border-radius: ${borders.radius.circle};
    border-width: ${borders.sizes.md};
    border-style: solid;
    border-color: ${({ active, finished }) => (active || finished ? color.primary.pure : color.grayscale.white)};
    background-color: ${({ active, finished }) => (active && !finished ? color.primary.pure : 'transparent')};
    font-family: ${font.family.heading};
    font-size: ${font.size.small};
    font-weight: ${font.weight.bold};
    line-height: ${font.lineHeight.sm};
  }
`

export const NextIcon = styled(Icon)`
  display: none;
  color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.xl}) {
    display: block;
    position: relative;
    left: -${space.md};
  }

  @media (min-width: ${breakpoint.hd}) {
    left: -${space.xl};
  }
`

export const TopCalculatorGradient = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: ${space.xl2};
  height: 100%;
  background: rgb(0, 38, 62);
  background: linear-gradient(90deg, rgba(0, 38, 62, 0) 0%, rgba(0, 38, 62, 0.55) 60%, rgba(0, 38, 62, 1) 100%);

  @media (min-width: ${breakpoint.xl}) {
    display: none;
  }
`
export const LeftButton = styled.a<StepStylesProps>`
  text-decoration: none;
  margin-right: ${space.xs2};
  opacity: ${({ active }) => active && '0.5'};
  &:hover {
    text-decoration: none;
  }
`
export const RightButton = styled.a<StepStylesProps>`
  text-decoration: none;
  opacity: ${({ finished }) => finished && '0.5'};
  margin-right: 2rem;
  cursor: ${({ isDisabled }) => (isDisabled ? 'none' : 'inherit')};
  pointer-events: ${({ isDisabled }) => (isDisabled ? 'none' : 'auto')};
  opacity: ${({ isDisabled }) => (isDisabled ? '0.4' : '1')};
  &:hover {
    text-decoration: none;
  }
`
