import { useMutation, useIsMutating, useQuery, useQueryClient } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getDsData, updateMutateDesignService, updateMutateDesignServiceBrief } from '@/infra/services/designService'
import { getPrices } from '@/infra/services'

export const useMutateDesignService = () => {
  const queryClient = useQueryClient()
  const { mutate, isSuccess, data, isLoading } = useMutation({
    mutationKey: [StorageKey.DESIGN_SERVICES],
    mutationFn: (payload: any) => updateMutateDesignService(payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.DESIGN_SERVICES] })
    }
  })

  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess,
    response: data
  }
}

/**
 *
 * Get Design Service data.
 *
 * @param locale - Language
 * @param briefId - Brief Id
 * @returns
 */
export const useGetDs = (locale: string = 'es', briefId: string | string[]) =>
  useQuery([StorageKey.DESIGN_SERVICES_DATA, locale], {
    queryFn: () => getDsData(locale, briefId),
    onSuccess: () => {},
    enabled: !!locale,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })

export const useMutateDesignServiceBrief = (locale: string = 'es') => {
  const queryClient = useQueryClient()
  const { mutate, isSuccess, data, isLoading } = useMutation({
    mutationKey: [StorageKey.DESIGN_SERVICES_MUTATION],
    mutationFn: (payload: any) =>
      updateMutateDesignServiceBrief(locale, payload.content, payload.cartId, payload.customerId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.DESIGN_SERVICES] })
    }
  })

  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess,
    response: data
  }
}
