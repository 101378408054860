/*
 *
 * DS => Design Serive
 *
 *
 * */
import { ds_prices } from '@/client/modules/DesignService/data/initialSelection'
import { DS_ACTIONS } from './constants'

export const DsReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case DS_ACTIONS.SET_DS_SELECTION:
      return {
        ...state,
        ds_selection: payload.ds_selection
      }

    case DS_ACTIONS.SET_DS_PRICES:
      return {
        ...state,
        ds_prices: payload.ds_prices
      }
    case DS_ACTIONS.SET_DS_BASIC_FIELD_RESPONSE:
      return {
        ...state,
        basic_fields_response: payload.basic_fields_response
      }
    case DS_ACTIONS.SET_STEP:
      return {
        ...state,
        current_step: payload.currentStep
      }

    default:
      throw new Error('No case for that type')
  }
}
