import React, { FC, memo } from 'react'
import * as S from './styles'
import { CheckboxProps } from './types'
import { Text } from '../Text'
import { Image } from '@/client/components'

export const ImageCheckbox: FC<CheckboxProps> = memo(
  ({
    kind = 'square',
    type = 'checkbox',
    id,
    name,
    value,
    checked,
    onChange,
    label,
    description,
    src,
    width,
    height,
    borderKind,
    ...rest
  }) => (
    <S.CheckboxContainer htmlFor={id} {...rest}>
      <S.HiddenInput type={type} id={id} name={name} value={value} checked={checked} onClick={onChange} readOnly />
      <S.Shape kind={kind} checked={checked}>
        <S.InnerShape kind={kind} checked={checked} />
      </S.Shape>
      <S.CheckBoxImage width={width} height={height} src={src} borderKind={borderKind}>
        <Image src={src} width={width} height={width} />
      </S.CheckBoxImage>
      <S.Infos>
        <Text
          as='span'
          className='checkbox-desc-box'
          margin='0 0.25rem 0 0'
          dangerouslySetInnerHTML={{ __html: label }}
        />

        {description && (
          <Text as='span' color='primary' margin='0'>
            {description}
          </Text>
        )}
      </S.Infos>
    </S.CheckboxContainer>
  )
)

ImageCheckbox.displayName = 'ImageCheckbox'
