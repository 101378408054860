import React, { FC, memo } from 'react'
import * as S from './styles'
import { TagProps } from './types'

export const Tag: FC<TagProps> = memo(
  ({ kind = 'default', size = 'default', isActive = false, action, children, ...rest }) => (
    <S.Tag
      as={kind === 'filter' ? 'button' : 'span'}
      kind={kind}
      size={size}
      onClick={action}
      active={isActive}
      {...rest}
    >
      {children}
    </S.Tag>
  )
)

Tag.displayName = 'Tag'
