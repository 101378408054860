import { useCallback, useId, useState } from 'react'
import Image from 'next/image'
import { useDropzone } from 'react-dropzone'
import { useFormContext } from 'react-hook-form'
import { ControlledErrorMessage } from '@/client/components/ControlledErrorMessage'
import { Icon } from '@/client/components/Icon'
import { useFileUpload } from '@/client/hooks/useChunkUpload'
import { uploadFile } from '@/infra/services/uploadFile'
import * as S from './styles'
import { Props } from './types'

export const ControlledChunkFile = ({ name, props, hideErrorMessage = false }: Props) => {
  const id = useId()
  const [isEmpty, setIsEmpty] = useState(true)
  const [preview, setPreview] = useState<string>()
  const { setValue, formState, trigger } = useFormContext()

  const isError = !!formState.errors[name]

  const { addFile, progress, abort, status } = useFileUpload({
    queryFn: uploadFile,
    onSuccess: ({ response }) => {
      setPreview(response?.preview)
      setValue(name, response?.preflight_hash, { shouldValidate: true })
      trigger(name)
      setIsEmpty(false)
    },
    onAbort: () => {
      setPreview(undefined)
      setValue(name, '')
      setIsEmpty(true)
    }
  })

  const upload = async (files: FileList | File[]) => {
    if (files.length < 1) {
      abort()
      setValue(name, '')
      setIsEmpty(true)
      return
    }
    setIsEmpty(false)
    await addFile({ newFile: files[0], props })
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: upload,
    accept: { 'application/pdf': [] },
    multiple: false
  })

  const PreView = useCallback(() => {
    if (isEmpty || isDragActive) {
      return (
        <>
          <Icon name='upload-cloud' size='s9' color={isError ? '#99002F' : '#00263e'} />
          {!hideErrorMessage && <ControlledErrorMessage name={name} />}
        </>
      )
    }
    if (status === 'ongoing') {
      return (
        <S.ProgressContainer>
          <S.Progress style={{ width: progress + '%' }} />
          <S.ProgressText>{progress.toFixed(2) + '%'}</S.ProgressText>
        </S.ProgressContainer>
      )
    }
    if (status === 'finished' && preview) {
      return (
        <S.ProgressContainer>
          <Image src={preview} alt={''} fill />
        </S.ProgressContainer>
      )
    }
    return null
  }, [isDragActive, hideErrorMessage, isEmpty, isError, name, preview, progress, status])

  return (
    <S.Container {...getRootProps()} activeDND={isDragActive} isError={isError} htmlFor={id}>
      {PreView()}

      <S.HiddenInput
        id={id}
        type='file'
        multiple={false}
        accept='application/pdf'
        capture
        onChange={(event) => event.target.files && upload(event.target.files)}
        {...getInputProps()}
      />
    </S.Container>
  )
}
