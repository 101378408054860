import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import {
  Row,
  Col,
  Breadcrumb,
  Checkbox,
  Button,
  Link,
  Modal,
  Input,
  Select2,
  Loader,
  ProgressLoader,
  ProxyImage
} from '@/client/components'
import * as S from './styles'
import { Icon } from '@/client/components/Icon'
import { Text } from '@/client/components/Text'
import { MultiTableProps } from './types'
import {
  useGetFolderEntries,
  useGetCustomTableData,
  useGetUserInfos,
  useRouterPush,
  useGetPreview,
  useGetImageProxy,
  useMutateDeleteFile,
  useClearQuery
} from '@/client/hooks'
import { formatDate } from '@/client/utils/formatDate'
import { CloseModal } from '../../CookieConsent/styles'
import { useMultiChunkUpload } from '@/client/hooks/useMultiChunkUpload'
import { uploadChunkFiles } from '@/infra/services/uploadFile'
import { useMutation } from '@tanstack/react-query'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import router, { useRouter } from 'next/router'
import { boolean } from 'zod'
import { Controls } from './Controls'
import Image from 'next/image'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const CustomFiles: FC<MultiTableProps> = memo(({ tableType, page, searchItem, currentFolderName, content }) => {
  const [eachItem, setEachItem] = useState<string[]>([])
  const [showDropdown, setShowDropdown] = useState(null)
  const [eachSortItem, setEachSortItem] = useState<any>([])
  const [labelWidth, setLabelWidth] = useState<any>(40)
  const [labelHeight, setLabelHeight] = useState<any>(40)
  const { asPath, query, pathname, defaultLocale, routerPush } = useRouterPush()

  /**
   *
   * Context for Label Library
   *
   */
  const {
    addToCopySelection,
    copy_selection,
    setCurrentPage,
    currentFolder,
    currentPage,
    totalPage,
    setTotalPage,
    setLoader,
    setMessages,
    uploadModal,
    setUploadModal,
    setCurrentFolder,
    changesOnFolder,
    changesOnFile,
    searchKeyword,
    setCurrentFolderName
  } = useContext(LabelLibraryContext)

  /**
   *
   *
   */

  const [fileSize, setFileSize] = useState({ width: 40, height: 40 })
  const { data: { customer_id } = {}, isLoading: userInfosLoading } = useGetUserInfos()

  /**
   *
   * Fetch Table data
   *
   */
  const {
    data: tableData,
    isLoading,
    refetch,
    isSuccess: customTableDataReady,
    status: customTableLoadingStatus
  } = useGetCustomTableData(
    {
      tableType: tableType,
      page: currentPage,
      customerFolder: currentFolderName,
      keyword: searchItem
    },
    customer_id ? customer_id : undefined
  )

  /**
   *
   *
   */
  const [refinedResults, setRefinedResults] = useState([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [formLoader, setFormLoader] = useState(false)
  const [folderName, setFolderName] = useState('')
  // Upload Folder setlector
  const [folderSelector, setFolderSelector] = useState([{ id: 1, name: '' }])
  const [selectedFolder, setSelectedFolder] = useState(0)
  const { data: folderEntries } = useGetFolderEntries(customer_id ? customer_id : undefined)
  const [buffer, setBuffer] = useState<any>([])
  const [currentProgress, setCurrentProgress] = useState<number[]>([])
  const { events } = useRouter()
  const [failedStatus, setFailedStatus] = useState(false)
  const [loadetShowStatus, setLoadetShowStatus] = useState(false)
  const [successMessage, setSuccessMessage] = useState(false)
  const [activePreview, setActivePreview] = useState<number | null>(null)
  const [subActivePreview, setSubActivePreview] = useState<number | null>(null)
  const { mutate: getPreview } = useGetPreview()
  const { mutate: clearQuery } = useClearQuery()
  const { addFile, status, currentItem } = useMultiChunkUpload({
    queryFn: uploadChunkFiles,
    onFinally: ({ item, response, final, progress = 0 }) => {
      if (!item) {
        setFailedStatus(true)
        setLoader(false)
        return false
      }
      console.log(item, progress, '<:progress')
      setFailedStatus(false)
      // setCurrentProgress(progress)
      setCurrentProgress((prevProgress) => {
        const newProgress: any = [...prevProgress] // Create a copy of the current state
        newProgress[item.fileUploadindex ? item.fileUploadindex : 0] = progress // Update the specific index
        return newProgress // Return the new array
      })

      refetch()
      setLoader(false)
    },
    onAbort: () => {
      console.log('Upload aborted')
    }
  })

  const selectRow = (elemId, filename) => {
    setEachItem((prevItems) => [
      ...prevItems.slice(1), // Remove the first item
      tableData['subSorts'].find((e) => e['id'] === elemId) // Add the new item to the end
    ])
    addToCopySelection({
      filename: filename,
      customerFileId: elemId
    })
  }

  const selectSubSortsRow = (elem, subSortId) => {
    setEachSortItem((prevItems) =>
      prevItems.map((item) => {
        if (item.id === elem.id) {
          const isPresent = item.subSorts.includes(subSortId)
          const updatedSubSorts = isPresent
            ? item.subSorts.filter((id) => id !== subSortId)
            : [...item.subSorts, subSortId]

          return {
            ...item,
            subSorts: updatedSubSorts
          }
        }
        return item
      })
    )
  }

  const isSubSortsChecked = (e, subSortId) => {
    const element = eachSortItem.find((el) => el.id === e)

    // Check if the element exists and if subSortId is in the subSorts array
    if (element) {
      return element.subSorts.includes(subSortId)
    }

    // Return false if no element is found with the matching id
    return false
  }

  const toggleMore = (i) => {
    showDropdown === i ? setShowDropdown(null) : setShowDropdown(i)
  }

  /*
   * Submit form.
   */
  const handleSubmitForm = async () => {
    setFormLoader(true)
    // await createFolder({ folderName: 'test-folder' })
    setUploadModal(false)
  }

  /*
   * Validate form.
   */
  const handleValidateForm = () => {
    // return formError
    return false
  }

  /*
   * Handle form change for folder creation.
   */
  const handleChangeForm = (newName) => {
    // setFolderName(newName.target.value)
    // zodParsing(newName.target.value)
  }

  const openReupload = () => {
    // setIsModalOpen(true)
    setUploadModal(true)
  }

  const changeFolder = (e) => {
    setCurrentFolder(Number(e.target.value))
    setSelectedFolder(e.target.value)
  }

  const upload = async () => {
    try {
      setLoadetShowStatus(true)
      await addFile(buffer, { width: labelWidth, height: labelHeight, page: selectedFolder, customer_id: customer_id })
    } catch (err) {
      setLoadetShowStatus(false)
      setFailedStatus(true)
    }
  }

  const reuploadAction = () => {
    setCurrentProgress([])
    setBuffer([])
    setSuccessMessage(false)
  }

  // Set/Update folder contents here (for upload).
  const setFolderContents = () => {
    const folderSelectorMutation = folderEntries && [
      { name: 'Select a folder', id: 0 },
      ...folderEntries.map((e) => {
        return {
          name: e['folder_name'],
          id: e['id']
        }
      })
    ]
    const getCurrentFolder =
      folderEntries && folderEntries.length > 0 && folderEntries.filter((e) => e['id'] === currentFolder)
    if (getCurrentFolder && getCurrentFolder.length > 0) {
      setCurrentFolderName(getCurrentFolder[0]['folder_name'])
    }
    setFolderSelector(folderSelectorMutation)
  }

  useEffect(() => {
    setLoader(true)
    refetch()
    setCurrentPage(page)
    setFolderContents()

    setLoader(false)
  }, [])

  useEffect(() => {
    setLoader(true)
    refetch()
    setFolderContents()
    if (tableData) {
      setTotalPage(tableData['last_page'])
      setLoader(false)
    }
  }, [changesOnFile, changesOnFolder, currentPage, currentFolder, customer_id, folderEntries])

  useEffect(() => {
    if (tableData && tableData.data.length == 0) {
      setMessages('Sorry, There is no data associated with this folder')
    } else {
      setMessages('')
    }
    if (tableData) {
      setTotalPage(tableData['last_page'])
      setLoader(false)
    }
  }, [tableData, customTableDataReady, customTableLoadingStatus])

  useEffect(() => {
    console.log(currentProgress, 'currentProgress')
    if (
      currentProgress.length !== 0 &&
      currentProgress.length === buffer.length &&
      currentProgress.every((num) => num === 100)
    ) {
      setTimeout(() => {
        setSuccessMessage(true)
      }, 1000)
    }
  }, [currentProgress])

  const processUploadFiles = async (files) => {
    for (let index = 0; index < files.length; index++) {
      const file = files[index]
      const fileData = {
        file,
        progress: 0,
        fileUploadindex: index // Add the index here
      }

      setBuffer((prev) => [...prev, fileData])
      setCurrentProgress((prev) => [...prev, 0])
    }
  }

  const removeFromBuffer = (index) => {
    setFailedStatus(false)
    setBuffer((prevG) => prevG.filter((_, idx) => idx !== index))
    setCurrentProgress([])
    setBuffer([])
    setSuccessMessage(false)
  }

  const goNext = () => {
    const getNextPage = totalPage === currentPage ? currentPage : currentPage + 1
    const refinedPath = asPath.split('?')[0]
    setCurrentPage(currentPage + 1)
    router.push({
      pathname: refinedPath,
      query: { page: getNextPage }
    })
  }

  const goPrevious = () => {
    const previousPage = currentPage === 1 ? currentPage : currentPage - 1
    const refinedPath = asPath.split('?')[0]
    setCurrentPage(currentPage - 1)
    router.push({
      pathname: refinedPath,
      query: { page: previousPage }
    })
  }

  const getModalContent = (id, item) => {
    const getData = content['modal_group']['modal'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }
  const getInputFieldContent = (id, item) => {
    const getData = content['input_field'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }
  const getButtonContent = (id, item) => {
    const getData = content['button_group']['action_button'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }

  // Generate image link based on page
  const getImageLink = (url, pagshowPrevieweParam) => {
    const newUrl = url + `&page=${pagshowPrevieweParam === '' ? 1 : pagshowPrevieweParam}`
    return newUrl + '&width=1024&height=1024'
  }

  // Show preivew for parent elements
  const showPreview = (index: number) => {
    if (activePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setSubActivePreview(null)
      setActivePreview(index)
    }
  }

  const closeUploadModal = () => {
    setBuffer([])
    setCurrentProgress([])
  }
  // Show preivew for sub elements
  const showSubPreview = (index: number) => {
    if (subActivePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setActivePreview(null)
      setSubActivePreview(index)
    }
  }

  // Handles outside click to hide preview components
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null

      if (activePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
      if (subActivePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
    }

    // Adding event listener to the document
    document.addEventListener('click', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [activePreview, subActivePreview])

  const getTableContent = (id, item) => {
    const getData = content['table_content']['main_table'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }

  return (
    <S.MultitableWrap>
      <>
        <Row className='multitable-row' middle='xs'>
          <Col xs='12' className='mutile-table-outer-row-header' noGutter>
            <Row isFull className='mutile-table-inner-row mutile-table-inner-row-header' middle='xs'>
              <Col xs='6' noGutter>
                <Row isFull middle='xs'>
                  <Col xs='1' className='multitable-select-field'></Col>
                  <Col xs='2'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('preview', 'table_cell_name')}
                    </Text>
                  </Col>
                  <Col xs='9'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('filename', 'table_cell_name')}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col xs='2' className='sorts-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('pages', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' className='size-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('size', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' className='createdate-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('create_date', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' className='lastorder-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('last_order_no', 'table_cell_name')}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
      {tableData?.filteredResults.map((elm, i) => (
        <S.multiTableRow key={i + 'table-data'}>
          <Row className='multitable-row' middle='xs'>
            <Col xs='12' noGutter>
              <Row isFull className='mutile-table-inner-row' middle='xs'>
                <Col xs='12' hd='6' noGutter>
                  <Row isFull middle='xs'>
                    <Col xs='1'>
                      <Checkbox
                        key={elm['id']}
                        id={elm['id']}
                        value={elm['id']}
                        name={elm['id']}
                        label={''}
                        description={''}
                        checked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
                        onChange={() => selectRow(elm['id'], elm['filename'])}
                        kind='circle'
                      />
                    </Col>
                    <Col xs='2' style={{ position: 'relative' }}>
                      {elm['preview_link'] || elm['preview_link'] !== '' ? (
                        <ProxyImage
                          src={getImageLink(elm['preview_link'], '')}
                          page={currentPage}
                          width={127}
                          height={92}
                          alt={'Preview image'}
                          title={'Preview Image'}
                          index={i}
                          showPreview={showPreview}
                          isOpen={activePreview === i}
                        />
                      ) : (
                        <img src='/img/my-account/no-image.png' alt='Preview no image' style={{ maxWidth: '100%' }} />
                      )}
                    </Col>
                    <Col xs='9'>
                      <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                        {elm['filename']}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                <Col xs='12' md='4' hd='2' className='sorts-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['number_of_pages']}
                  </Text>
                </Col>
                <Col xs='12' md='4' hd='2' className='size-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['width']} X {elm['height']}
                  </Text>
                </Col>
                <Col xs='12' md='4' hd='2' className='createdate-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {formatDate(elm['created_at'])}
                  </Text>
                </Col>
                <Col xs='12' md='4' hd='2' className='lastorder-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['last_order_number']}
                  </Text>
                </Col>
              </Row>
              {eachItem.length > 0 &&
                eachItem
                  .filter((e) => e['id'] === elm['id'])
                  .map((subSort, subSortIndex) => (
                    <>
                      {Array.from({ length: subSort['subSorts'] }, (_, index) => (
                        <Row isFull middle='xs' className='multiple-select-field-sorts' key={index + 'sub'}>
                          <Col xs='6'>
                            <Row isFull middle='xs'>
                              <Col xs='2' noGutter>
                                <Checkbox
                                  key={subSort['id']}
                                  id={subSort['id']}
                                  value={subSort['id']}
                                  name={subSort['id']}
                                  label={''}
                                  description={''}
                                  onChange={() => selectSubSortsRow(elm, subSort['id'])}
                                  kind='circle'
                                  checked={true}
                                />
                              </Col>
                              <Col xs='2' style={{ position: 'relative' }}>
                                <ProxyImage
                                  src={getImageLink(elm['preview_link'], index + 1)}
                                  page={currentPage}
                                  width={127}
                                  height={92}
                                  alt={'Preview image'}
                                  title={'Preview Image'}
                                  index={index}
                                  showPreview={showSubPreview}
                                  isOpen={subActivePreview === index}
                                />
                              </Col>
                              <Col xs='8'>
                                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                                  {elm['filename']}
                                </Text>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs='2' className='sorts-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {index + 1}/{elm['number_of_pages']}
                            </Text>
                          </Col>
                          <Col xs='2' className='size-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['width']} X {elm['height']}
                            </Text>
                          </Col>
                          <Col xs='2' className='createdate-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {formatDate(elm['created_at'])}
                            </Text>
                          </Col>
                          <Col xs='2' className='lastorder-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['last_order_number']}
                            </Text>
                          </Col>
                        </Row>
                      ))}

                      {!elm['preflight_task_status'] && (
                        <>
                          <S.ActionBlockWrapper>
                            <S.ActionBlock>
                              <S.ActionIcon>
                                <Icon name='x' size='s4' />
                              </S.ActionIcon>
                              <S.ActionInfo>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      '<b style="color: red">Action required.</b> <b style="color: #72747B">Preflight found mistakes</b> - <b style="color: red">Dielines are missing!</b><p> Please Re-upload your file(s) again or Book a Professional artwork service (here).</p>'
                                  }}
                                />
                              </S.ActionInfo>
                            </S.ActionBlock>
                            <Button kind='warning' onClick={openReupload}>
                              Re-Upload
                            </Button>
                          </S.ActionBlockWrapper>

                          <S.ArtWorkServiceAssitance>
                            <S.ArtWorkServiceAssitanceContent>
                              <Icon name='group-set' size='s6' />{' '}
                              <p>Our professional team will prepare your label design file for printing. </p>
                              <Button kind='warning'>Order Arwork Service</Button>
                            </S.ArtWorkServiceAssitanceContent>
                          </S.ArtWorkServiceAssitance>
                        </>
                      )}
                    </>
                  ))}
            </Col>
          </Row>
        </S.multiTableRow>
      ))}
      <Controls currentPage={currentPage} goPrevious={goPrevious} goNext={goNext} totalPage={totalPage} />

      {uploadModal && (
        <S.ModalCustom>
          <div className='modal-custom'>
            <Modal
              size='medium'
              close={() => {
                setUploadModal(false)
                closeUploadModal()
              }}
              buttonName={'Add'}
              cancelButtonName={'cancel'}
              func={handleSubmitForm}
              isLoading={isLoading}
              isDisabled={handleValidateForm()}
              infos={{ title: '' }}
              className='upload-modal'
              showActions={false}
            >
              <S.UploadWrapper>
                <>
                  {successMessage && (
                    <S.UploadSuccessContent>
                      <div>
                        <Text family='text' size='md'>
                          Files have been successfully uploaded
                        </Text>
                      </div>
                      <div>
                        <S.ButtonGroup>
                          <div style={{ marginRight: '0.5rem' }}>
                            <Link
                              key={'go-to-folder'}
                              onClick={() => closeUploadModal()}
                              href={`/my-account/label-library/custom-files/${currentFolder}`}
                            >
                              <Button kind='warning' id='header_main_button'>
                                Go to Folder
                              </Button>
                            </Link>
                          </div>

                          <Button kind='warning' onClick={() => reuploadAction()}>
                            Re upload
                          </Button>
                        </S.ButtonGroup>
                      </div>
                    </S.UploadSuccessContent>
                  )}
                </>
                <S.UploadContent>
                  <S.UploadControl>
                    <S.UploadSettings>
                      <S.UploadHeader gradient={true}>{getModalContent('upload-modal', 'title')}</S.UploadHeader>

                      <S.UploadSettingsContainer>
                        <S.UFields>
                          <S.Block>{getInputFieldContent('label-size-width', 'input_label')}</S.Block>
                          <S.InlineInput>
                            <Input
                              dimension='large'
                              type='number'
                              min={100}
                              max={'max'}
                              key={'id'}
                              id={'id'}
                              name={'name'}
                              value={labelWidth}
                              onChange={(e) => setLabelWidth(e.target.value)}
                              label={getInputFieldContent('label-size-width', 'input_label')}
                            />
                            <span className='up-divider'>X</span>
                            <Input
                              dimension='large'
                              type='number'
                              min={'min'}
                              max={'max'}
                              key={'id'}
                              id={'id'}
                              name={'name'}
                              value={labelHeight}
                              onChange={(e) => setLabelHeight(e.target.value)}
                              label={getInputFieldContent('label-size-height', 'input_label')}
                            />
                          </S.InlineInput>
                        </S.UFields>

                        <S.Block>{getInputFieldContent('save-to-folder', 'input_label')} Save to folder</S.Block>
                        <S.InlineInput>
                          <Select2
                            id='savefolder'
                            placeholder='savefolder'
                            name='savefolder'
                            noSelectIco
                            value={selectedFolder}
                            onChange={changeFolder}
                            options={folderSelector}
                          />
                        </S.InlineInput>
                        <S.ButtonGroup>
                          <Button
                            kind='warning'
                            disabled={buffer.length === 0 || selectedFolder == 0}
                            onClick={() => upload()}
                          >
                            {getModalContent('upload-modal', 'success_action')}
                          </Button>
                          <Button kind='white' onClick={() => setUploadModal(false)}>
                            {getModalContent('upload-modal', 'failure_action')}
                          </Button>
                        </S.ButtonGroup>
                      </S.UploadSettingsContainer>
                    </S.UploadSettings>
                    <S.UploadActionWrapper>
                      <S.UploadHeader>{getModalContent('upload-modal-file', 'title')} </S.UploadHeader>
                      <S.UploadActionWrapperContainer>
                        <S.ButtonGroup>
                          <S.UploadButton>
                            <label htmlFor='file'>
                              <span className='label-upload-button'>
                                <Icon name='upload-set' size='s1' />
                                {getButtonContent('add_file', 'name')}
                              </span>
                              <input
                                type='file'
                                multiple
                                id='file'
                                name='file'
                                accept='application/pdf'
                                onChange={(event) => event.target.files && processUploadFiles(event.target.files)}
                              />
                            </label>
                          </S.UploadButton>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                '<p style="margin:0; font-size: 12px">Drag and drop the files here or upload your files with “Add” button. </p><p style="margin:0; font-size: 12px"><b>Only PDF format is acceptable. </b></p>'
                            }}
                          ></div>
                        </S.ButtonGroup>
                        <S.UploadedIems>
                          {buffer.map((e, index) => {
                            return (
                              currentProgress[index] <= 100 && (
                                <S.UList key={index} isDisabled={currentProgress[index] < 100}>
                                  <a
                                    href='javascript:void(0)'
                                    onClick={() => removeFromBuffer(index)}
                                    className='ul-list-close'
                                  >
                                    <Icon name='close-circle-s' />
                                  </a>
                                  <S.UListItem>
                                    <Icon name='pdf-cco' size='s6' />
                                  </S.UListItem>
                                  <S.UListItem>
                                    <p>{e['file']['name']}</p>
                                  </S.UListItem>
                                  {loadetShowStatus && (
                                    <ProgressLoader
                                      value={currentProgress[index] ? currentProgress[index] : 0}
                                      maxValue={100}
                                      width={40}
                                      height={40}
                                      noText
                                      failed={failedStatus && currentProgress[index]['progress'] === 0}
                                    />
                                  )}
                                </S.UList>
                              )
                            )
                          })}
                        </S.UploadedIems>
                      </S.UploadActionWrapperContainer>
                    </S.UploadActionWrapper>
                  </S.UploadControl>
                </S.UploadContent>
              </S.UploadWrapper>
            </Modal>
          </div>
        </S.ModalCustom>
      )}
    </S.MultitableWrap>
  )
})

CustomFiles.displayName = 'CustomFiles'
