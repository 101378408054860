import React, { FC, memo } from 'react'
import * as S from './styles'
import { CheckboxProps } from './types'
import { Text } from '../Text'

export const Checkbox: FC<CheckboxProps> = memo(
  ({ kind = 'square', type = 'checkbox', id, name, value, checked, onChange, label, description, ...rest }) => (
    <S.CheckboxContainer htmlFor={id} {...rest} className='check-box-ms'>
      <S.HiddenInput type={type} id={id} name={name} value={value} checked={checked} onClick={onChange} readOnly />
      <S.Shape kind={kind} checked={checked} className='shape-class'>
        <S.InnerShape kind={kind} checked={checked} className='inner-shape-class' />
      </S.Shape>

      <S.Infos>
        <Text
          as='span'
          className='checkbox-desc-box'
          margin='0 0.25rem 0 0'
          dangerouslySetInnerHTML={{ __html: label }}
        />

        {description && (
          <Text as='span' color='primary' margin='0'>
            {description}
          </Text>
        )}
      </S.Infos>
    </S.CheckboxContainer>
  )
)

Checkbox.displayName = 'Checkbox'
