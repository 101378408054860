/*
 *
 * DS => Design Serive
 *
 *
 * */
export enum E_LOADER {
  SET_LOAD = 'SET_LOAD',
  SET_MESSAGE = 'SET_MESSAGE'
}
