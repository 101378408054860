import { api } from '@/client/utils'

/**
 * Gets basic artwork information with help of a kid id that comes from emails to client.
 *
 * @param {string} kidId - Id passes through url (it will be a link through mail).
 * @returns {Promise} A Promise that resolves when the submission is complete.
 */
export const getArtworkServicesInfo = async (kidId: string) => {
  const url = `/customers/artworkservices/${kidId}`
  return await api.get(url)
}


/**
 * Submits artwork information (feedbacks).
 *
 * @param {Object} feebackPayload - The data to be submitted as artwork information.
 * @param {string} kid - Id passes through url (it will be a link through mail).
 * @returns {Promise} A Promise that resolves when the submission is complete.
 */
export const submitArtworkInfo = async (feebackPayload: object, kid: string) => {
  const url = `/customers/artworkservices/${kid}`
  return await api.post(url, JSON.stringify(feebackPayload))
}

/**
 * Gets Artwork images (from sort array)
 *
 * @param {string} imageUrl - URL comes from sort array thumbnail.
 * @returns {Promise} A Promise that resolves when the submission is complete.
 */
export const getArtworkInfoImages = async (imageUrl: string) => {
  const getImageData = await api.get(imageUrl, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'arraybuffer'
    }
  })
  return getImageData
}

