import { fetchJson } from '@/client/utils'

export const searchPages = async (locale: string, value?: string) => {
  const apiUrl = process.env.NEXT_PUBLIC_API_CMS
  let url = `${apiUrl}/search?per_page=5&subtype=page,products&search=${value}`

  if (!apiUrl?.includes(locale)) {
    url = url + `&lang=${locale}`
  }

  const data = await fetchJson(url)
  return data
}
