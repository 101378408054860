import { Auth } from 'aws-amplify'
import { protectedApi } from '@/client/utils'
import { api } from '@/client/utils'

export const getUserInfos = async () => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/customers/${attributes['custom:customer_id']}`
  const { data } = await protectedApi.get(url)
  return data?.data
}

export const updateUserInfos = async (data) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/customers/${attributes['custom:customer_id']}/updateCustomer`
  return await protectedApi.post(url, JSON.stringify(data))
}
