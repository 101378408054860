const space = {
  xs2: '0.25rem',
  xs: '0.5rem',
  sm: '0.75rem',
  md: '1rem',
  lg: '1.5rem',
  xl: '2rem',
  xl2: '2.5rem',
  xl3: '3rem',
  xl4: '4rem',
  xl5: '5rem',
  xl6: '6rem',
  xl7: '7rem',
  xl8: '8rem',
  xl9: '9rem',
  xl10: '10rem',
  xl11: '11rem'
}

export default Object.freeze(space)
