import React, { FC, memo } from 'react'
import * as S from './styles'
import { ListItemProps } from './types'

export const ListItem: FC<ListItemProps> = memo(
  ({
    children,
    iconName = 'checkmark',
    weight = 'normal',
    inverse = false,
    fontSize,
    border = '',
    iconSize = 's2',
    className
  }) => (
    <S.ListItem weight={weight} inverse={inverse} size={fontSize ? fontSize : 'inherit'} border={border}>
      <S.ListItemIcon name={iconName} size={iconSize} className={className} />
      {children}
    </S.ListItem>
  )
)

ListItem.displayName = 'ListItem'
