import { useQuery, useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import { parseCookies } from 'nookies'
import { StorageKey, Cookies, ICartSelection } from '@/client/types'
import {
  createFolder,
  deleteFolder,
  getFolderEntries,
  getTabledata,
  moveToDownload,
  moveToFolder,
  renameFolder,
  getCustomTabledata,
  getPreview,
  deleteFile
} from '@/infra/services/labelLibraryService'
import { IMutateFolder, SubSort } from './types'
import { useGetUserInfos } from '../userInfos'

/**
 *
 * Generate sub items for each table.
 * @returns data and subSorts array
 *
 */
export const generateSubItems = (currentItems, keyword) => {
  if (!currentItems) return undefined
  const currentItemsData = currentItems['data']
  const subSorts: SubSort[] = []
  for (let i = 0; i < currentItemsData.length; i++) {
    const { id, number_of_pages } = currentItemsData[i]
    subSorts.push({
      id: id,
      subSorts: number_of_pages,
      checkedSorts: new Array(number_of_pages).fill(true)
    })
  }
  currentItems['subSorts'] = subSorts
  // Filter results with keyword
  const filteredResult = currentItems?.data?.filter((e) => e['filename'].toLowerCase().includes(keyword))
  currentItems['filteredResults'] = filteredResult
  return currentItems
}
/**
 *
 * Generate folderITems.
 *
 */
export const generateFolderItems = (folderData) => {
  const folderSelectorMutation = folderData && [
    ...folderData.map((e) => {
      return {
        name: e['folder_name'],
        id: e['id']
      }
    })
  ]
  folderData['options'] = folderSelectorMutation
  return folderData
}

/**
 *
 * @returns Get folder entries for label library (especially for custom folders)
 */
export const useGetFolderEntries = (customer_id) => {
  const cookies = parseCookies()
  const cartId = cookies[Cookies.CART_ID]

  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS],
    queryFn: () => getFolderEntries(customer_id || ''),
    select: (data) => generateFolderItems(data),
    refetchOnWindowFocus: false,
    enabled: !!customer_id
  })

  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    isSuccess,
    remove,
    status,
    data: data || undefined
  }
}

/**
 *
 * @returns Table data for each page inside label library.
 */

export const useGetTableData = ({ tableType, page, customerFolder, keyword }, customer_id) => {
  const cookies = parseCookies()
  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA],
    queryFn: () => getTabledata(tableType, page, customerFolder, customer_id),
    enabled: false,
    refetchOnWindowFocus: false,
    select: (data) => generateSubItems(data, keyword)
  })
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    isSuccess,
    status,
    remove,
    data: data
  }
}
/**
 *
 * @returns Table data for each page inside label library.
 */

export const useGetCustomTableData = ({ tableType, page, customerFolder, keyword }, customer_id) => {
  const cookies = parseCookies()
  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess, status } = useQuery({
    queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA],
    queryFn: () => getCustomTabledata(tableType, page, customerFolder, customer_id),
    enabled: false,
    refetchOnWindowFocus: false,
    select: (data) => generateSubItems(data, keyword)
  })
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    remove,
    isSuccess,
    status,
    data: data
  }
}

/**
 *
 * @returns Create folder.
 *
 */

export const useMutateFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_UPDATE],
    mutationFn: ({ payload, customer_id }: IMutateFolder) => createFolder(payload, customer_id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Delete folder.
 *
 */

export const useMutateDeleteFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, id }: any) => deleteFolder(customer_id, id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Create folder.
 *
 */

export const useMutateDeleteFile = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, folderIdid, fileId, payload }: any) =>
      deleteFile(customer_id, folderIdid, fileId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}

/**
 *
 * @returns Rename folder.
 *
 */

export const useMutateRenameFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, id, payload }: any) => renameFolder(customer_id, id, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
export const useMutateMovetoFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, folderIdid, fileId, payload }: any) =>
      moveToFolder(customer_id, folderIdid, fileId, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
export const useDownloadFolder = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, folderIdid, fileId, currentPage, payload }: any) =>
      moveToDownload(customer_id, folderIdid, fileId, currentPage, payload),
    onSuccess: () => {}
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
export const useClearQuery = () => {
  const queryClient = useQueryClient()
  const { mutate } = useMutation({
    mutationKey: ['CLEAR_KEY'],
    mutationFn: (): any => {
      queryClient.resetQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA], exact: true })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate
  }
}

export const useGetPreview = () => {
  const queryClient = useQueryClient()
  const isLoading = useIsMutating([StorageKey.LABEL_LIBRARY_FOLDER_UPDATE])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.LABEL_LIBRARY_FOLDER_DELETE],
    mutationFn: ({ customer_id, folderIdid, fileId, currentPage, payload }: any) =>
      getPreview(customer_id, folderIdid, fileId, currentPage, payload),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_FOLDERS] })
      queryClient.invalidateQueries({ queryKey: [StorageKey.LABEL_LIBRARY_TABLE_DATA] })
    }
  })
  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
