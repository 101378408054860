import styled from 'styled-components'
import { getWeight, getSize, getTransform } from './TextVariants'
import { TextStyleProps } from './types'
import { tokens } from '../theme'
const { color, font, space, breakpoint } = tokens

export const Text = styled.p<TextStyleProps>`
  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};
  color: ${(p) => color.text[p.color]};
  font-family: ${(p) => font.family[p.family]};
  text-align: ${(p) => p.align};
  line-height: ${(p) => p.lineHeight || font.lineHeight.lg};
  ${(p) => getWeight[p.weight]}
  ${(p) => getSize[p.size]}
  ${(p) => getTransform[p.transform]}
  ${(p) => p.underline && 'text-decoration: underline;'}
  ${(p) => p.italic && 'font-style: italic;'}
  ${(p) =>
    p.ellipsis
      ? `
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
      : ` white-space: break-spaces;`}

  & > svg,
  & > .icon {
    margin-right: ${space.xs2};
  }

  & > svg {
    vertical-align: middle;
  }
`
export const ContentPart = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  @media (min-width: ${breakpoint.xl}) {
    max-width: 30%;
  }
`
export const ContentHeader = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: ${space.md};

  & p {
    color: ${color.grayscale.lightDark};
    margin: 0;
  }
`
export const InputPart = styled.div<{ inline?: boolean }>`
  max-width: 80%;
  width: 100%;
  display: ${(p) => (p.inline ? 'flex' : 'block')};
  flex-wrap: ${(p) => (p.inline ? 'wrap' : 'none')};
`
export const ContentDescription = styled.div`
  margin-bottom: ${space.md};

  @media (min-width: ${breakpoint.lg2}) {
    margin-bottom: 0;

    & span {
      display: block;
      width: 50%;
    }
  }
`

export const FormWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoint.xl}) {
    flex-direction: row;
  }
`
export const ExpandIcon = styled.div<{ isVisible?: boolean }>`
  margin-left: 10px;
  & span {
    transform: ${(p) => (p.isVisible ? 'none' : 'rotate(180deg)')};
    display: inline-block;
    border: 1px solid ${color.grayscale.darker};
    border-radius: 100px;
    padding: 2px;
  }
`
