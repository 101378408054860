import { useId } from 'react'
import { useFormContext } from 'react-hook-form'
import { SelectCard } from '@/client/components/SelectCard'
import { Props } from './types'

export const ControlledSelectCard = <T,>({ label, items }: Props<T>) => {
  const { register, watch } = useFormContext()
  const formId = useId()
  const fieldWatcher = watch(label)

  return (
    <>
      {items.map(({ value, icon, id, name, disabled = true, key, ...rest }) => (
        <SelectCard
          key={key + formId + id}
          id={formId + id}
          iconName={icon}
          label={name}
          defaultValue={id}
          defaultChecked={id == fieldWatcher}
          disabled={false}
          register={register(label)}
          {...rest}
        />
      ))}
    </>
  )
}

ControlledSelectCard.displayName = 'SelectCard'
