import { FC } from 'react'
import ImageNext, { ImageProps, StaticImageData } from 'next/image'
import { useWindowSize } from '@/client/hooks/windowSize'
import { FeaturedImage } from '@/client/types/Image'

type ImageComponentType = Partial<ImageProps> & {
  hasBreakpoint?: boolean
  imageData?: FeaturedImage
  src?: string | StaticImageData
  alt?: string
}

export const Image: FC<ImageComponentType> = ({
  hasBreakpoint,
  imageData,
  src,
  width,
  height,
  alt,
  title,
  ...rest
}) => {
  const { breakpoint } = useWindowSize()

  const getCurrentBreakpoint = () => {
    const breakpointValue = ['desktop', 'mobile', 'tablet'].forEach((value) => {
      if (!imageData) return 'desktop'

      const hasImage = Boolean(imageData[value]?.url)

      if (!hasImage && value === 'mobile') return 'tablet'
      if (!hasImage && value === 'tablet') return 'desktop'
      if (!hasImage && value === 'desktop') return 'tablet'
    })

    return breakpointValue
  }

  if (hasBreakpoint && breakpoint && imageData) {
    const breakpointVerified = (imageData[breakpoint]?.url ? breakpoint : getCurrentBreakpoint()) || 'desktop'

    return (
      <ImageNext
        src={imageData[breakpointVerified].url}
        width={imageData[breakpointVerified].width}
        height={imageData[breakpointVerified].height}
        alt={imageData[breakpointVerified].alt || imageData[breakpointVerified].title}
        {...rest}
      />
    )
  }

  if (src) {
    return <ImageNext src={src} width={width} height={height} alt={alt || title || ''} {...rest} />
  }

  return <></>
}
