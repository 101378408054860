export const formatDate = (val) => {
  const isoDate = val

  // Create a Date object
  const date = new Date(isoDate)

  // Extract the parts
  const day = String(date.getUTCDate()).padStart(2, '0')
  const month = String(date.getUTCMonth() + 1).padStart(2, '0') // Months are zero-based
  const year = date.getUTCFullYear()

  // Format to DD.MM.YYYY
  const formattedDate = `${day}.${month}.${year}`
  return formattedDate
}
