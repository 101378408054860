import { useQuery } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getUserInfos, updateUserInfos } from '@/infra/services'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useGetUserInfos = () => useQuery([StorageKey.USER_INFOS], () => getUserInfos())

export const useMutateUser = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (data: {}) => updateUserInfos(data),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.USER_INFOS] })
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.USER_INFOS] })
    }
  })
}
