import { Auth } from 'aws-amplify'
import { IAddress, IAddressIsActiveResponse, IAddressQuery, ICompleteAddress } from '@/client/types'
import { protectedApi } from '@/client/utils'

export const getUserAddresses = async () => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/addresses/${attributes['custom:customer_id']}`
  const { data } = await protectedApi.get<IAddressQuery>(url)
  return data?.addresses
}

export const createUserAddress = async (endpoint: string, address: IAddress) => {
  const { data } = await protectedApi.post(endpoint, address)
  return data
}

export const updateUserAddress = async (endpoint: string, address: IAddress) => {
  const { data } = await protectedApi.put(endpoint, address)
  return data
}

export const isActiveAddress = async (address: IAddress) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/address/isActiveAddress/${attributes['custom:customer_id']}/${address?.id}`
  const { data } = await protectedApi.get<IAddressIsActiveResponse>(url)
  return data?.status === 200
}
