/*
 *
 * DS => Design Serive
 *
 *
 * */
import { createContext } from 'react'
import { useValues } from './DsProvider'

export const DsContext = createContext({} as ReturnType<typeof useValues>)
