import { api, protectedApi } from '@/client/utils'
import { InitialSelection } from '@/client/utils/design-service/DESIGN_SERVICE_API'

export const updateMutateDesignService = async (data) => {
  const url = `/artwork-assistant`
  return {
    success: true,
    redirect_url: '/design-service?briefId=4359348953450340834985304'
  }
}
/**
 *
 * @param payload  Design service form data.
 * @param cartId  Current user cart ID.
 * @param customerId  Current user log in ID.
 * @returns Cart updates.
 */
export const updateMutateDesignServiceBrief = async (locale, payload, cartId, customerId) => {
  const url = `/design-service/${locale}/${customerId}/${cartId}`
  const { data } = await protectedApi.post(url, JSON.stringify(payload))
  return data
}

/**
 *
 * @param locale Lang
 * @returns Initial Design service form fields.
 */
export const getDsData = async (locale: string, briefId: string | string[]) => {
  const url = `/design-service/${locale}`
  const { data } = await api.get<any>(url)
  return data
}
