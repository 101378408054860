import styled from 'styled-components'
import { StylesProps } from './types'
import { tokens } from '../theme'
const { breakpoint, color, font, space } = tokens

export const Breadcrumb = styled.ol<StylesProps>`
  display: none;

  @media (min-width: ${breakpoint.lg}) {
    display: inline-block;
    width: auto;
    padding-left: 0;
    color: ${(p) => (p.inverse ? color.grayscale.white : color.grayscale.darker)};
    font-size: ${font.size.small};
    line-height: ${font.lineHeight.sm};
  }
`

export const BreadcrumbItem = styled.li<StylesProps>`
  display: inline-block;

  a {
    color: ${(p) => (p.inverse ? color.grayscale.white : color.grayscale.dark)};
  }

  &:last-of-type {
    color: ${(p) => (p.inverse ? color.grayscale.white : color.primary.darker)};
    font-weight: ${font.weight.bold};
  }

  &:not(:last-child)::after {
    content: '>';
    line-height: ${font.size.small};
    display: inline-block;
    margin: 0 ${space.xs};
    vertical-align: baseline;
    color: ${(p) => (p.inverse ? color.grayscale.white : color.grayscale.gray)};
    font-size: ${font.size.small};
  }
`
