import { useCallback } from 'react'
import { IOrderCard } from '@/client/modules/MyOrders/types'

export const useCurrentOrders = (orders, isService: boolean, service?: string) => {
  const itemId = Number(service)
  const isDetail = Boolean(service)

  const formattedList = useCallback(() => {
    if (isService) return orders

    const newOrders: IOrderCard[] = []
    orders?.forEach((order) => {
      newOrders.push(...order.jobs)
    })

    return orders
  }, [isService, orders])

  const newOrders = formattedList()
  const list = isDetail ? newOrders.filter(({ id }) => id === itemId) : newOrders

  return {
    list,
    isDetail
  }
}
