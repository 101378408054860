import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import { Row, Col, Breadcrumb, Checkbox, Button, Link, ProxyImage } from '@/client/components'
import * as S from './styles'
import { Icon } from '@/client/components/Icon'
import { Text } from '@/client/components/Text'
import { MultiTableProps, SubSortProps } from './types'
import { useGetFolderEntries, useGetTableData, useGetUserInfos, useRouterPush } from '@/client/hooks'
import { formatDate } from '@/client/utils/formatDate'
import router from 'next/router'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
import { Controls } from './Controls'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const OrderTable: FC<MultiTableProps> = memo(({ tableType, page, searchItem, currentFolderName, content }) => {
  const [eachItem, setEachItem] = useState<string[]>([])
  const [showDropdown, setShowDropdown] = useState(null)
  const { data: { customer_id } = {}, isLoading: userInfosLoading } = useGetUserInfos()
  const [eachSortItem, setEachSortItem] = useState<any>([])
  const { data: folderEntries } = useGetFolderEntries(customer_id ? customer_id : undefined)
  const [folderSelector, setFolderSelector] = useState([{ id: 1, name: '' }])
  const [activePreview, setActivePreview] = useState<number | null>(null)
  const [subActivePreview, setSubActivePreview] = useState<number | null>(null)

  /**
   *
   * Context for Label Library
   *
   */
  const {
    addToCopySelection,
    copy_selection,
    setCurrentPage,
    currentFolder,
    currentPage,
    totalPage,
    setTotalPage,
    setLoader,
    setMessages,
    uploadModal,
    setUploadModal,
    setCurrentFolder,
    changesOnFolder,
    changesOnFile,
    searchKeyword,
    setCurrentFolderName,
    setOrderNo
  } = useContext(LabelLibraryContext)
  const { asPath, query, pathname, defaultLocale, routerPush } = useRouterPush()

  const {
    data: tableData,
    isLoading,
    isSuccess: customTableDataReady,
    status: customTableLoadingStatus,
    refetch
  } = useGetTableData(
    {
      tableType: 'service',
      page: page,
      customerFolder: '',
      keyword: searchItem
    },
    customer_id ? customer_id : undefined
  )
  const [refinedResults, setRefinedResults] = useState([])
  const selectRow = (elemId, filename, lastOrderNo) => {
    setEachItem((prevItems) => [
      ...prevItems.slice(1), // Remove the first item
      tableData['subSorts'].find((e) => e['id'] === elemId) // Add the new item to the end
    ])
    addToCopySelection({
      filename: filename,
      customerFileId: elemId
    })
    setOrderNo(lastOrderNo)
  }

  const selectSubSortsRow = (elem, subSortId) => {
    setEachSortItem((prevItems) =>
      prevItems.map((item) => {
        if (item.id === elem.id) {
          const isPresent = item.subSorts.includes(subSortId)
          const updatedSubSorts = isPresent
            ? item.subSorts.filter((id) => id !== subSortId)
            : [...item.subSorts, subSortId]

          return {
            ...item,
            subSorts: updatedSubSorts
          }
        }
        return item
      })
    )
  }

  const isSubSortsChecked = (e, subSortId) => {
    const element = eachSortItem.find((el) => el.id === e)

    // Check if the element exists and if subSortId is in the subSorts array
    if (element) {
      return element.subSorts.includes(subSortId)
    }

    // Return false if no element is found with the matching id
    return false
  }

  const toggleMore = (i) => {
    showDropdown === i ? setShowDropdown(null) : setShowDropdown(i)
  }

  const getTableContent = (id, item) => {
    const getData = content['table_content']['main_table'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }
  const getButtonContent = (id, item) => {
    const getData = content['button_group']['action_button'].filter((e) => e['id'] === id)
    return getData.length > 0 ? getData[0][item] : ''
  }

  // Set/Update folder contents here (for upload).
  const setFolderContents = () => {
    const folderSelectorMutation = folderEntries && [
      { name: 'Select a folder', id: 0 },
      ...folderEntries.map((e) => {
        return {
          name: e['folder_name'],
          id: e['id']
        }
      })
    ]
    const getCurrentFolder =
      folderEntries && folderEntries.length > 0 && folderEntries.filter((e) => e['id'] === currentFolder)
    console.log(getCurrentFolder, 'sdf')
    if (getCurrentFolder && getCurrentFolder.length > 0) {
      setCurrentFolderName(getCurrentFolder[0]['folder_name'])
    }
    setFolderSelector(folderSelectorMutation)
  }

  useEffect(() => {
    setLoader(true)
    refetch()
    setCurrentPage(page)
    setFolderContents()

    setLoader(false)
  }, [])

  useEffect(() => {
    setLoader(true)
    refetch()
    setFolderContents()
    if (tableData) {
      setTotalPage(tableData['last_page'])
      setLoader(false)
    }
  }, [changesOnFile, changesOnFolder, currentPage, currentFolder, customer_id, folderEntries])

  useEffect(() => {
    if (tableData && tableData.data.length == 0) {
      setMessages('Sorry, There is no data associated with this folder')
    } else {
      setMessages('')
    }
    if (tableData) {
      setTotalPage(tableData['last_page'])
      setLoader(false)
    }
  }, [tableData, customTableDataReady, customTableLoadingStatus])

  const goNext = () => {
    const getNextPage = totalPage === currentPage ? currentPage : currentPage + 1
    const refinedPath = asPath.split('?')[0]
    setCurrentPage(currentPage + 1)
    router.push({
      pathname: refinedPath,
      query: { page: getNextPage }
    })
  }

  const goPrevious = () => {
    const previousPage = currentPage === 1 ? currentPage : currentPage - 1
    const refinedPath = asPath.split('?')[0]
    setCurrentPage(currentPage - 1)
    router.push({
      pathname: refinedPath,
      query: { page: previousPage }
    })
  }

  // Generate image link based on page
  const getImageLink = (url, pagshowPrevieweParam) => {
    const newUrl = url + `&page=${pagshowPrevieweParam === '' ? 1 : pagshowPrevieweParam}`
    return newUrl + '&width=1024&height=1024'
  }
  // Show preivew for parent elements
  const showPreview = (index: number) => {
    if (activePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setSubActivePreview(null)
      setActivePreview(index)
    }
  }

  // Show preivew for sub elements
  const showSubPreview = (index: number) => {
    if (subActivePreview === index) {
      // If the same image is clicked again, close the popup
      setActivePreview(null)
      setSubActivePreview(null)
    } else {
      setActivePreview(null)
      setSubActivePreview(index)
    }
  }

  // Handles outside click to hide preview components
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement | null

      if (activePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
      if (subActivePreview !== null && target && !target.closest('.popup')) {
        setActivePreview(null)
        setSubActivePreview(null)
      }
    }

    // Adding event listener to the document
    document.addEventListener('click', handleClickOutside)

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [activePreview, subActivePreview])

  return (
    <S.MultitableWrap>
      <>
        <Row className='multitable-row' middle='xs'>
          <Col xs='12' className='mutile-table-outer-row-header' noGutter>
            <Row isFull className='mutile-table-inner-row mutile-table-inner-row-header' middle='xs'>
              <Col xs='6' noGutter>
                <Row isFull middle='xs'>
                  <Col xs='1' className='multitable-select-field'></Col>
                  <Col xs='2'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('preview', 'table_cell_name')}
                    </Text>
                  </Col>
                  <Col xs='9'>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('filename', 'table_cell_name')}
                    </Text>
                  </Col>
                </Row>
              </Col>

              <Col xs='2' className='sorts-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('sorts', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' className='size-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('sizes', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' className='createdate-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('create_date', 'table_cell_name')}
                </Text>
              </Col>
              <Col xs='2' className='lastorder-col'>
                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                  {getTableContent('last_order_no', 'table_cell_name')}
                </Text>
              </Col>
            </Row>
          </Col>
        </Row>
      </>
      {tableData?.filteredResults.map((elm, i) => (
        <S.multiTableRow>
          <Row className='multitable-row' middle='xs'>
            <Col xs='12' noGutter>
              <Row isFull className='mutile-table-inner-row' middle='xs'>
                <Col xs='6' noGutter>
                  <Row isFull middle='xs'>
                    <Col xs='1'>
                      <Checkbox
                        key={elm['id']}
                        id={elm['id']}
                        value={elm['id']}
                        name={elm['id']}
                        label={''}
                        description={''}
                        checked={eachItem.length > 0 && eachItem[0]['id'] === elm['id']}
                        onChange={() => selectRow(elm['id'], elm['filename'], elm['last_order_number'])}
                        kind='circle'
                      />
                    </Col>
                    <Col xs='2' style={{ position: 'relative' }}>
                      {elm['preview_link'] || elm['preview_link'] !== '' ? (
                        <ProxyImage
                          src={getImageLink(elm['preview_link'], '')}
                          page={currentPage}
                          width={127}
                          height={92}
                          alt={'Preview image'}
                          title={'Preview Image'}
                          index={i}
                          showPreview={showPreview}
                          isOpen={activePreview === i}
                        />
                      ) : (
                        <img src='/img/my-account/no-image.png' alt='Preview no image' style={{ maxWidth: '100%' }} />
                      )}
                    </Col>
                    <Col xs='9'>
                      <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                        {elm['filename']}
                      </Text>
                    </Col>
                  </Row>
                </Col>

                <Col xs='2' className='sorts-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['number_of_pages']}
                  </Text>
                </Col>
                <Col xs='2' className='size-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['width']} X {elm['height']}
                  </Text>
                </Col>
                <Col xs='2' className='createdate-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {formatDate(elm['created_at'])}
                  </Text>
                </Col>
                <Col xs='2' className='lastorder-col'>
                  <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                    {elm['last_order_number']}
                  </Text>
                </Col>
              </Row>
              {eachItem.length > 0 &&
                eachItem
                  .filter((e) => e['id'] === elm['id'])
                  .map((subSort, subSortIndex) => (
                    <>
                      {Array.from({ length: subSort['subSorts'] }, (_, index) => (
                        <Row isFull middle='xs' className='multiple-select-field-sorts'>
                          <Col xs='6'>
                            <Row isFull middle='xs'>
                              <Col xs='2' noGutter>
                                <Checkbox
                                  key={subSort['id']}
                                  id={subSort['id']}
                                  value={subSort['id']}
                                  name={subSort['id']}
                                  label={''}
                                  description={''}
                                  onChange={() => selectSubSortsRow(elm, subSort['id'])}
                                  kind='circle'
                                  checked={true}
                                />
                              </Col>
                              <Col xs='2' style={{ position: 'relative' }}>
                                {elm['preview_link'] || elm['preview_link'] !== '' ? (
                                  <ProxyImage
                                    src={getImageLink(elm['preview_link'], index + 1)}
                                    page={currentPage}
                                    width={127}
                                    height={92}
                                    alt={'Preview image'}
                                    title={'Preview Image'}
                                    index={index}
                                    showPreview={showSubPreview}
                                    isOpen={subActivePreview === index}
                                  />
                                ) : (
                                  <img
                                    src='/img/my-account/no-image.png'
                                    alt='Preview no image'
                                    style={{ maxWidth: '100%' }}
                                  />
                                )}
                              </Col>
                              <Col xs='8'>
                                <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                                  {elm['filename']}
                                </Text>
                              </Col>
                            </Row>
                          </Col>

                          <Col xs='2' className='sorts-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {index + 1}/{elm['number_of_pages']}
                            </Text>
                          </Col>
                          <Col xs='2' className='size-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['width']} X {elm['height']}
                            </Text>
                          </Col>
                          <Col xs='2' className='createdate-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {formatDate(elm['created_at'])}
                            </Text>
                          </Col>
                          <Col xs='2' className='lastorder-col'>
                            <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                              {elm['last_order_number']}ff
                            </Text>
                          </Col>
                        </Row>
                      ))}
                    </>
                  ))}
            </Col>
          </Row>
          {/* <Row className='mutile-table-inner-row'>
            <Col xs='2' noGutter>
              <S.TBlock>
                <Icon name='custom-sheet' />
                <S.TBlockContent>
                  <div>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      SO-24-0284
                    </Text>
                  </div>
                  <S.OptionStyle>
                    <S.OptionLink onClick={() => toggleMore(i)}>
                      <Text family='text' color='primary' size='sm' weight='medium' style={{ margin: '0' }}>
                        {getTableContent('more_details', 'more_details')} <Icon name='chevron-down' />
                      </Text>
                      {showDropdown && showDropdown === i ? (
                        <S.OptionData>This section is for details.</S.OptionData>
                      ) : (
                        <></>
                      )}
                    </S.OptionLink>
                  </S.OptionStyle>
                </S.TBlockContent>
              </S.TBlock>
            </Col>
            <Col xs='3' noGutter>
              <S.TBlock>
                <Icon name='custom-sheet' />
                <S.TBlockContent>
                  <div>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('size', 'size')}
                    </Text>
                  </div>
                  <div>
                    <Text family='text' color='primary' size='sm' weight='medium' style={{ margin: '0' }}>
                      200X 90
                    </Text>
                  </div>
                </S.TBlockContent>
              </S.TBlock>
            </Col>
            <Col xs='3' noGutter>
              <S.TBlock>
                <Icon name='custom-sheet' />
                <S.TBlockContent>
                  <div>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('quantity', 'quantity')}
                    </Text>
                  </div>
                  <div>
                    <Text family='text' color='primary' size='sm' weight='medium' style={{ margin: '0' }}>
                      100000
                    </Text>
                  </div>
                </S.TBlockContent>
              </S.TBlock>
            </Col>
            <Col xs='3' noGutter>
              <S.TBlock>
                <Icon name='custom-sheet' />
                <S.TBlockContent>
                  <div>
                    <Text family='text' color='weak' size='sm' style={{ margin: '0' }}>
                      {getTableContent('material', 'material')}
                    </Text>
                  </div>
                  <div>
                    <Text family='text' color='primary' size='sm' weight='medium' style={{ margin: '0' }}>
                      Premium Matt White 90g
                    </Text>
                  </div>
                </S.TBlockContent>
              </S.TBlock>
            </Col>
          </Row> */}
        </S.multiTableRow>
      ))}
      <Controls currentPage={currentPage} goPrevious={goPrevious} goNext={goNext} totalPage={totalPage} />
    </S.MultitableWrap>
  )
})

OrderTable.displayName = 'OrderTable'
