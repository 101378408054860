import { Auth } from 'aws-amplify'
import axios, { AxiosHeaders, AxiosResponse, CreateAxiosDefaults, InternalAxiosRequestConfig } from 'axios'

const baseConfig: CreateAxiosDefaults = {
  withCredentials: true,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
}
const baseConfigMultipart: CreateAxiosDefaults = {
  withCredentials: true,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json'
  }
}

export const api = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_V2_PUBLIC,
  ...baseConfig,
  headers: {
    ...baseConfig.headers,
    'x-api-key': '67hNk6y7sT2yiZ5xK69C037Axz0Tbs6w85Wg6EvA'
  }
})

const getAuthorizationToken = async () => {
  const session = await Auth.currentSession()
  return session.getIdToken().getJwtToken()
}

const recreateConfig = async (config: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
  const headers = new AxiosHeaders(config.headers)
  headers.set('Authorization', await getAuthorizationToken())
  return {
    ...config,
    headers: headers
  }
}

const handleResponseError = async (error) => {
  const config = error?.config
  if (error?.response?.status === 401 && !config?.sent) {
    return axios(await recreateConfig(config))
  }
  return Promise.reject(error)
}

export const protectedApi = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_V2_PRIVATE,
  ...baseConfig,
  headers: {
    ...baseConfig.headers
  }
})

protectedApi.interceptors.request.use(
  (config) => recreateConfig(config),
  (error) => Promise.reject(error)
)

protectedApi.interceptors.response.use((response: AxiosResponse): AxiosResponse => response, handleResponseError)
