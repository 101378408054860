import { useReducer, useCallback } from 'react'
import { DsContext } from './DsContext'
import { DS_ACTIONS } from './constants'
import { DsReducer } from './reducer'
import {
  ds_prices,
  initialSelection,
  basic_fields,
  total,
  basic_fields_response,
  packageType,
  step
} from '@/client/modules/DesignService/data/initialSelection'
export const useValues = () => {
  /*
   *
   * Adding static value for initial selection
   * from Design service module.
   *
   * Later it will be updated with API response,
   * and will be checked with ZOD validation.
   *
   * */
  const [state, dispatch] = useReducer(DsReducer, {
    ds_selection: initialSelection,
    ds_prices: ds_prices,
    basic_fields_response: basic_fields_response,
    current_step: step
  })

  const updateDSSelection = (ds_selection) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DS_ACTIONS.SET_DS_SELECTION,
        payload: {
          ds_selection: ds_selection
        }
      })
      resolve(true)
    })
  }

  const updateStep = (Val) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: DS_ACTIONS.SET_STEP,
        payload: {
          currentStep: Val
        }
      })
      resolve(true)
    })
  }

  /**
   * Update prices based on the API data.
   * Specifically for UI only.
   *
   */
  const updateDSPrices = useCallback((ds_prices) => {
    const { sorts, premium_services, additional_forms, package_type, high_priority } = ds_prices['basic_fields']
    const totalVal = sorts + premium_services + additional_forms + package_type + high_priority
    ds_prices[basic_fields][total] = totalVal
    dispatch({
      type: DS_ACTIONS.SET_DS_PRICES,
      payload: {
        ds_prices: ds_prices
      }
    })
  }, [])

  /**
   * Clear Design Service data.
   * Clear Price data.
   */
  const clearDsData = useCallback(() => {
    updateDSSelection(initialSelection)
    updateDSPrices(ds_prices)
  }, [])

  /**
   * Basic field response
   * basic_field form response.
   */
  const updateDSBasic_field_response = useCallback((basic_fields_response) => {
    dispatch({
      type: DS_ACTIONS.SET_DS_BASIC_FIELD_RESPONSE,
      payload: {
        basic_fields_response: basic_fields_response
      }
    })
  }, [])

  return {
    ds_selection: state.ds_selection,
    ds_prices: state.ds_prices,
    basic_fields_response: state.basic_fields_response,
    current_step: state.current_step,
    updateDSSelection,
    updateDSPrices,
    updateDSBasic_field_response,
    clearDsData,
    updateStep
  }
}

export const DsProvider = ({ children }) => {
  const value = useValues()
  return <DsContext.Provider value={value}>{children}</DsContext.Provider>
}
