import React, { FC, memo } from 'react'
import * as S from './styles'
import { CardLinkProps } from './types'
import { Icon } from '../Icon'
import { Text } from '../Text'

export const CardLink: FC<CardLinkProps> = memo(({ link, icon, title, subtitle, ...rest }) => (
  <S.PdfBox href={link} target='_blank' {...rest}>
    <S.IconWrapper>
      <Icon id={title} name={icon} size='s6' color='#008FBE' />
    </S.IconWrapper>
    <div>
      <Text size='sm' weight='bold' margin='0'>
        {title}
      </Text>
      <Text size='sm' color='weak' margin='0'>
        {subtitle}
      </Text>
    </div>
  </S.PdfBox>
))

CardLink.displayName = 'CardLink'
