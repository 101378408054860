import { useCallback, useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { AddFile, BlobProps, ChunkFile, Item, Props, Status } from './types'
import { useMutation } from '@tanstack/react-query'
import { protectedApi } from '@/client/utils'

export const useMultiChunkUpload = <P, R>({
  queryFn,
  onSuccess,
  onFinally,
  chunkSize = 5_000_000,
  onAbort
}: Props<P, R>) => {
  const [files, setFiles] = useState([])
  const [status, setStatus] = useState('idle')
  const [currentItem, setCurrentItem] = useState(null)

  const { mutateAsync } = useMutation({
    mutationFn: queryFn,
    onSuccess: (data, variables) => {
      console.log('Upload successful:', data)
    },
    onError: (error) => {
      console.error('Upload failed:', error)
    },
    onSettled: (data, error, variables) => {
      if (onFinally) {
        if (data) onFinally({ item: variables, progress: data['progress'] || 0 })
      }
    }
  })

  const Blob = ({ file, index, chunkSize }: BlobProps) => {
    const from = index * chunkSize
    const to = from + chunkSize
    const blob = file.slice(from, to)
    return blob
  }

  const addFile = useCallback(
    async (fileData, fileSize) => {
      setStatus('uploading')
      setCurrentItem(fileData)

      // const chunkSize = 1 * 1024 * 1024 // 1 MB
      const chunkSize = 700 * 1024 // 300 KB
      try {
        fileData.map(async (eFileData) => {
          const totalChunks = Math.ceil(eFileData.file.size / chunkSize)
          for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
            const start = chunkIndex * chunkSize
            const end = Math.min(start + chunkSize, eFileData.file.size)
            const chunk = eFileData.file.slice(start, end)
            const chunkDataSet = {
              chunk: chunk,
              chunkSize: chunkSize,
              totalChunks: totalChunks,
              chunkIndex: chunkIndex,
              filename: eFileData.file.name,
              width: fileSize.width,
              height: fileSize.height,
              fileConf: fileSize,
              index: chunkIndex,
              totalFileSize: eFileData.file.size,
              fileUploadindex: eFileData.fileUploadindex
            }

            const chunkTest = eFileData.file
            const mimeType = chunk.type || 'application/octet-stream' // Fallback MIME type

            await mutateAsync(chunkDataSet) // Upload the FormData
            setCurrentItem({ ...eFileData, chunk_index: chunkIndex + 1 })
          }
        })
      } catch (error) {
        // reject(true)
        // response = undefined
        if (error instanceof AxiosError) {
          if (error.code === '"ERR_CANCELED"') return onAbort?.()
        }
      }

      setStatus('completed')
    },
    [mutateAsync]
  )

  const abort = () => {
    setStatus('aborted')
    if (onAbort) {
      onAbort()
    }
  }

  const convertFileToBase64 = async (filepath: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(filepath)
      reader.onerror = (error) => reject(error)
      reader.onload = () => resolve(reader.result)
    })

  return { addFile, abort, status, currentItem }
}
