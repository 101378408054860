import styled from 'styled-components'
import { tokens } from '../theme'
const { font } = tokens

const sizes: Record<string, string> = {
  s1: font.size.xsmall,
  s2: font.size.small,
  s3: font.size.root,
  s4: font.size.h6,
  s5: font.size.h5,
  s6: font.size.h4,
  s7: font.size.h3,
  s8: font.size.h2,
  s9max: font.size.h2Max,
  s9: font.size.h1
}

export const Icon = styled.span<{ $size: string; $color?: string }>`
  font-size: ${(p) => sizes[p.$size]};
  color: ${(p) => p.$color};
`
