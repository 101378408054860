import styled from 'styled-components'
import borders from '../theme/borders'
import color from '../theme/color'
import space from '../theme/space'

export const PdfBox = styled.a`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${space.md};
  padding: ${space.sm} ${space.lg};
  background-color: ${color.grayscale.white};
  border-radius: ${borders.radius.md};

  &:hover {
    text-decoration: none;
  }
`

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: ${space.xl3};
  padding-right: ${space.lg};
  margin-right: ${space.lg};
  border-right: ${borders.sizes.sm} solid ${color.grayscale.light};
`
