import styled from 'styled-components'
import borders from '@/client/components/theme/borders'
import color from '@/client/components/theme/color'
import space from '@/client/components/theme/space'

export const ProgressContainer = styled.div`
  overflow: hidden;
  position: relative;
  text-align: center;
  border-radius: 0.375rem;
  border-width: 2px;
  cursor: pointer;
  width: 100%;
  height: 100%;
`
export const ProgressText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`
export const Progress = styled.div`
  height: 100%;
  border-radius: 0.375rem;
  background-color: ${color.primary.light};
`
export const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
`
export const Container = styled.label<{ activeDND: boolean; isError: boolean }>(
  ({ activeDND, isError }) => `
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 128px;
  height: 128px;
  padding: ${space.sm};
  border-radius: ${borders.radius.lg};
  border-width: ${borders.sizes.md};
  border-style: solid;
  border-color: ${isError ? color.text.danger : color.text['primary-dark']};
  text-align: center;
  cursor: pointer;
  background-color: ${activeDND ? color.neutral.dark : color.grayscale.white};
  &:hover {
    background-color: ${color.neutral.dark};
  }
`
)

export const ImageContainer = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${space.md};
  margin: ${space.sm};
  color: ${color.grayscale.dark};
  border-radius: ${borders.radius.circle};
  border-width: ${borders.sizes.md};
  border-style: solid;
`

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`
