import React, { FC, memo } from 'react'
import * as S from './styles'
import { FieldsetProps } from './types'

export const Fieldset: FC<FieldsetProps> = memo(
  ({ children, kind = 'column', fieldsetType = 'normal', newVersion = false, className, ...props }) => (
    <S.Fieldset kind={kind} newVersion={newVersion} className={className} {...props}>
      <S.Legend kind={kind} fieldsetType={fieldsetType} newVersion={newVersion}>
        {children[0]}
      </S.Legend>
      <S.Fields kind={kind} fieldsetType={fieldsetType} newVersion={newVersion}>
        {children[1]}
      </S.Fields>
    </S.Fieldset>
  )
)

Fieldset.displayName = 'Fieldset'
