import styled, { css } from 'styled-components'
import { ContainerStyleProps, GroupStyleProps } from './types'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'

export const Container = styled.span<ContainerStyleProps>`
  position: relative;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  color: ${color.grayscale.black};

  label {
    display: block;
    margin-bottom: ${space.xs};
    color: ${color.grayscale.dark};
    font-size: ${font.size.xsmall};
    line-height: ${font.lineHeight.sm};
  }

  ${(p) =>
    p.isBlock &&
    css`
      display: block;
      width: 100%;
    `}

  ${(p) =>
    p.isInline &&
    css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `}
`

export const Group = styled.span<GroupStyleProps>`
  display: flex;
  padding: 0 ${space.xs2};
  background-color: ${color.grayscale.white};
  outline: ${borders.sizes.sm} solid ${color.grayscale.light};
  border-radius: ${borders.radius.lg};

  &:focus-within,
  &:focus {
    outline: ${borders.sizes.md} solid ${color.primary.darker};
  }

  select {
    width: 100%;
    height: 4rem;
    padding-left: ${space.md};
    padding-right: ${space.md};
    background-color: transparent;
    border: 0;
    outline: 0;
    background-size: ${space.xs};
    font-size: ${font.size.root};
    color: ${color.text.normal};

    ::placeholder,
    ::-ms-input-placeholder,
    :-ms-input-placeholder {
      color: ${color.grayscale.dark};
    }
  }

  select:invalid {
    color: ${color.text.weak};
  }

  ${(p) =>
    p.isInline &&
    css`
      margin-left: ${space.xs};
      margin-right: ${space.xs};
    `}

  ${(p) =>
    p.disabled &&
    css`
      background-color: ${color.grayscale.lighter};
      color: ${color.grayscale.dark};
    `}

  ${(p) =>
    p.readOnly &&
    css`
      background-color: ${color.warning.pale};
      color: ${color.grayscale.dark};
    `}
`

export const Error = styled.span`
  position: absolute;
  display: block;
  margin-top: ${space.xs2};
  margin-left: ${space.md};
  color: ${color.danger.dark};
  font-size: ${font.size.xsmall};
  line-height: ${font.lineHeight.sm};
`
