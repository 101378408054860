import React, { FC, memo } from 'react'
import * as S from './styles'
import { CardAddressProps } from './types'
import { Icon } from '../Icon'
import { Text } from '../Text'

export const CardAddress: FC<CardAddressProps> = memo(
  ({ id, name, value, onChange, checked = false, address, address2, address3, city, country, onEdit, onRemove }) => (
    <S.Card htmlFor={id} checked={checked}>
      <S.HiddenInput id={id} name={name} type='radio' value={value} checked={checked} onChange={onChange} />

      <Text margin='0'>{address}</Text>
      {address2 && <Text margin='0'>{address2}</Text>}
      {address3 && <Text margin='0'>{address3}</Text>}
      <Text margin='0'>{city}</Text>
      <Text>{country}</Text>

      <S.Actions>
        <S.Button data-testid='cardaddress-id-edit' onClick={onEdit}>
          <Icon name='edit' /> Edit
        </S.Button>
        <S.Button data-testid='cardaddress-id-remove' onClick={onRemove}>
          <Icon name='trash' /> Remove
        </S.Button>
      </S.Actions>
    </S.Card>
  )
)

CardAddress.displayName = 'CardAddress'
