import { useQuery, useMutation, useQueryClient, useIsMutating } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getMaterial } from '@/infra/services/material'

export const useGetMaterial = (id: any = 0, locale: string = 'es') =>
  useQuery([StorageKey.MATERIAL, id], {
    queryFn: () => getMaterial(id, locale),
    enabled: !!id,
    staleTime: Infinity,
    refetchOnWindowFocus: false
  })
