import { ErrorMessage } from '@hookform/error-message'
import { useFormContext } from 'react-hook-form'
import { Text } from '@/client/components/Text'
import { ControlledErrorMessageType } from './types'

export const ControlledErrorMessage = ({ name }: ControlledErrorMessageType) => {
  const {
    formState: { errors }
  } = useFormContext()
  return <ErrorMessage errors={errors} name={name} render={({ message }) => <Text color='danger'>{message}</Text>} />
}
