const zindex = {
  dropdownOverlay: 990,
  dropdown: 1000,
  navbar: 1000,
  fixed: 1030,
  sticky: 1030,
  modalOverlay: 1040,
  modal: 1050,
  popover: 1060,
  tooltip: 1070,
  warning: 1080
}

export default Object.freeze(zindex)
