import { useQuery } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { getCalculatorInfos, getPackageInfos } from '@/infra/services'

export const useGetCalculatorInfos = (locale: string = 'es') =>
  useQuery([StorageKey.CALCULATOR_INFO, locale], {
    queryFn: () => getCalculatorInfos(locale),
    enabled: !!locale,
    staleTime: Infinity,
    keepPreviousData: true
})
