import { Auth } from 'aws-amplify'
import { protectedApi } from '@/client/utils'

const options = (state: string) => ({
  params: { filter: state === 'completed' ? 'completed' : 'current' }
})

export const getOrders = async (state: string) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/customers/${attributes['custom:customer_id']}/jobs`
  const { data } = await protectedApi.get(url, options(state))
  return data?.orders || []
}

export const getArtworkServices = async (state: string) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/customers/${attributes['custom:customer_id']}/artworkservices`
  const { data } = await protectedApi.get(url, options(state))
  return data?.services || []
}

export const getDesignServices = async (state: string) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/customers/${attributes['custom:customer_id']}/designs`
  const { data } = await protectedApi.get(url, options(state))
  return data?.designs || []
}
