import { useState, useEffect, useRef } from 'react'
import { theme } from '@/client/components'

type WindowSize = {
  width: number
  height: number
  breakpoint: string
  isXsBreakpoint: boolean
  isSmBreakpoint: boolean
  isMdBreakpoint: boolean
  isLgBreakpoint: boolean
  isXlBreakpoint: boolean
  isHdBreakpoint: boolean
}

type Ref = ReturnType<typeof setInterval> | null

const convertedBreakpoints = () => {
  const { xs, sm, md, lg, xl, hd, hd2, ul } = theme.breakpoint

  return {
    xs: Number(xs.split('rem')[0]),
    sm: Number(sm.split('rem')[0]),
    md: Number(md.split('rem')[0]),
    lg: Number(lg.split('rem')[0]),
    xl: Number(xl.split('rem')[0]),
    hd: Number(hd.split('rem')[0]),
    hd2: Number(hd2.split('rem')[0]),
    ul: Number(ul.split('rem')[0])
  }
}

const verifyBreakpoints = (width: number) => {
  const remWidth = width / 16
  const { xs, sm, md, lg, xl, hd, ul } = convertedBreakpoints()

  return {
    isXsBreakpoint: remWidth >= xs && remWidth < sm,
    isSmBreakpoint: remWidth >= sm && remWidth < md,
    isMdBreakpoint: remWidth >= md && remWidth < lg,
    isLgBreakpoint: remWidth >= lg && remWidth < xl,
    isXlBreakpoint: remWidth >= xl && remWidth < hd,
    isHdBreakpoint: remWidth >= hd && remWidth < ul,
    isUlBreakpoint: remWidth >= ul
  }
}

const getBreakpoint = () => {
  const widthSize = window.innerWidth / 16
  const { md, lg, hd, hd2 } = convertedBreakpoints()
  let breakpoint = 'mobile'

  if (widthSize > hd2) breakpoint = 'wide-desktop'
  if (widthSize > lg && widthSize <= hd2) breakpoint = 'desktop'
  if (widthSize > md && widthSize <= lg) breakpoint = 'tablet'

  return breakpoint
}

export const useWindowSize = (): WindowSize => {
  const timeout = useRef<Ref>(null)
  const [windowSize, setWindowSize] = useState<WindowSize>({
    width: 0,
    height: 0,
    breakpoint: '',
    isXsBreakpoint: false,
    isSmBreakpoint: false,
    isMdBreakpoint: false,
    isLgBreakpoint: false,
    isXlBreakpoint: false,
    isHdBreakpoint: false
  })

  useEffect(() => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
      breakpoint: getBreakpoint(),
      ...verifyBreakpoints(window.innerWidth)
    })
  }, [])

  useEffect(() => {
    const handleResize = () => {
      timeout?.current && clearTimeout(timeout.current)

      timeout.current = setTimeout(() => {
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
          breakpoint: getBreakpoint(),
          ...verifyBreakpoints(window.innerWidth)
        })
      }, 100)
    }

    window.addEventListener('resize', handleResize)
    handleResize()

    return () => window.removeEventListener('resize', handleResize)
  }, [timeout])

  return windowSize
}
