import { useMutation, useIsMutating } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { updateContact } from '@/infra/services/contact'

export const useMutateContact = (locale: string = 'es') => {
  const isLoading = useIsMutating([StorageKey.CONTACT])
  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.CONTACT],
    mutationFn: (data: {}) => updateContact(locale, data)
  })

  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
