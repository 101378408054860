import React, { FC, memo, useEffect, useRef, useState } from 'react'
import * as S from './styles'
import { RangeSlideProps } from './types'
import { Text } from '../Text'

export const RangeSlider: FC<RangeSlideProps> = memo(
  ({
    type,
    textLower,
    textUpper,
    margin,
    padding,
    size,
    color,
    id,
    className,
    value,
    defaultValue,
    onInput,
    step,
    min,
    max,
    disabled,
    orientation,
    rangeSlideDisabled,
    thumbsDisabled,
    spanLength,
    contents,
    sideLabel
  }) => {
    const rangeManagerRef = useRef<HTMLDivElement>(null)
    const [width, setWidth] = useState(0)

    useEffect(() => {
      if (rangeManagerRef.current) {
        setWidth(rangeManagerRef.current.offsetWidth)
      }
    }, [rangeManagerRef.current])

    return (
      <S.RangeSliderManager className='range-slider-block'>
        <S.TextAligner>
          <Text margin={margin} padding={padding} size={size} color={color} className={className}>
            {textLower}
          </Text>
          <Text margin={margin} padding={padding} size={size} color={color} className={className}>
            {textUpper}
          </Text>
        </S.TextAligner>

        <S.RangeWrapper>
          <S.ContentWrapper>
            {contents?.map(({ content_text }) => (
              <S.SpanContents width={width} spanLength={spanLength}>
                {content_text}
              </S.SpanContents>
            ))}
          </S.ContentWrapper>
          <div ref={rangeManagerRef} className='rangemanagerref'>
            {type === 'default' && (
              <S.RangeManager
                id={id}
                className={className}
                defaultValue={defaultValue}
                value={value}
                onInput={onInput}
                step={step}
                max={max}
                min={min}
                disabled={disabled}
                orientation={orientation}
                rangeSlideDisabled={rangeSlideDisabled}
                thumbsDisabled={thumbsDisabled}
              />
            )}
            {(type === 'singleThumb' || type === 'single-thumb') && (
              <S.RangeManager
                id={id}
                className={className}
                defaultValue={defaultValue}
                value={value}
                thumbsDisabled={[true, false]}
                rangeSlideDisabled={true}
                onInput={onInput}
                step={step}
                max={max}
                min={min}
                disabled={disabled}
                orientation={orientation}
              />
            )}
          </div>
        </S.RangeWrapper>
      </S.RangeSliderManager>
    )
  }
)

RangeSlider.displayName = 'RangeSlider'
