import { useEffect, useState } from 'react'
import { AxiosError } from 'axios'
import { AddFile, BlobProps, ChunkFile, Item, Props, Status } from './types'

export const useFileUpload = <P, R>({ queryFn, onSuccess, onFinally, chunkSize = 5_000_000, onAbort }: Props<P, R>) => {
  const [progress, setProgress] = useState(0)
  const [status, setStatus] = useState<Status>('finished')
  const [item, setCurrentItem] = useState<Item>({ index: -1 })
  const [props, setProps] = useState<P>({} as P)
  const [abortController, setAbortController] = useState(new AbortController())

  const currentFile = item?.file

  const upload = async () => {
    if (!currentFile) return
    if (abortController.signal.aborted) return
    const length = Math.ceil(currentFile.size / chunkSize)
    const staticData: Omit<ChunkFile, 'base64' | 'index'> = {
      name: currentFile.name,
      size: currentFile.size,
      length: length
    }
    let response: Awaited<R> | undefined
    let progress = 0
    try {
      for (let index = 0; index < length; index++) {
        const blob = Blob({ index, chunkSize, file: currentFile })
        const base64 = (await convertFileToBase64(blob as File)) as string
        response = await queryFn({
          file: { ...staticData, base64, index },
          signal: abortController.signal,
          props
        })
        progress = ((index + 1) / length) * 100
        setProgress(progress)
      }
    } catch (error) {
      response = undefined
      if (error instanceof AxiosError) {
        if (error.code === '"ERR_CANCELED"') return onAbort?.()
      }
    } finally {
      setStatus('finished')
      if (progress === 100) onSuccess?.({ response, item })
      onFinally?.({ response, item })
    }
  }

  const addFile: AddFile<P> = async ({ newFile, props, index }) => {
    setAbortController(new AbortController())
    setProgress(0)
    setProps(props)
    setStatus('ongoing')
    setCurrentItem({ file: newFile, index: index ?? -1 })
  }

  const abort = () => {
    abortController.abort()
  }

  const Blob = ({ file, index, chunkSize }: BlobProps) => {
    const from = index * chunkSize
    const to = from + chunkSize
    const blob = file.slice(from, to)
    return blob
  }

  const convertFileToBase64 = async (filepath: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(filepath)
      reader.onerror = (error) => reject(error)
      reader.onload = () => resolve(reader.result)
    })

  useEffect(() => {
    upload()
    return () => abortController.abort()
  }, [item])

  useEffect(() => {
    if (!onAbort) return
    abortController.signal.addEventListener('abort', onAbort)
    return () => abortController.signal.addEventListener('abort', onAbort)
  }, [abortController])

  return { progress, status, addFile, abort, currentItem: item }
}
