import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { parseCookies } from 'nookies'
import { StorageKey, Cookies, ICartSelection } from '@/client/types'
import {
  getCartItems,
  updateCart,
  addCartItem,
  updateCartItem,
  deleteCartItem,
  checkoutCart,
  addQuoteItemToCart,
  getProxyImage
} from '@/infra/services'
import { useState } from 'react'

export const useGetImageProxy = (imageSrc) => {
  const queryClient = useQueryClient()
  const [previousUserId, setPreviousUserId] = useState(null)
  const cookies = parseCookies()
  const { data, isLoading, isFetching, refetch, isRefetching, remove, isSuccess } = useQuery({
    queryKey: [imageSrc],
    queryFn: () => getProxyImage(imageSrc),
    refetchOnWindowFocus: false, // No refetch on window focus
    retry: true, // No retries on failure
    enabled: false,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [imageSrc] })
    }
  })
  return {
    isLoading,
    isFetching,
    refetch,
    isRefetching,
    remove,
    isSuccess,
    data
  }
}
