import styled, { css } from 'styled-components'
import { tokens } from '../theme'
const { borders, color, font, space, breakpoint } = tokens

const cardBackgroundColor = (version: string, kind: string) => {
  let backgroundColor = color.grayscale.white
  if (version === 'old' && kind === 'light') {
    backgroundColor = color.primary.pure
  }
  if (version === 'old' && kind === 'dark') {
    backgroundColor = color.primary.darker
  }
  if (version === 'new' && kind === 'light') {
    backgroundColor = color.grayscale.goldenBrown
  }
  if (version === 'new' && kind === 'dark') {
    backgroundColor = color.grayscale.darkNavyBlue
  }

  return css`
    background-color: ${backgroundColor};
  `
}

const cardHeaderStyle = (version: string) => {
  let height = '7rem'
  let textAlign = 'center'
  let lineHeight = '7rem'
  let fontSize = font.size.h4
  let fontWeight = font.weight.bold
  let paddingLeft = '0'

  if (version === 'new') {
    height = '3rem'
    textAlign = 'left'
    lineHeight = '3rem'
    fontSize = font.size.h5
    fontWeight = font.weight.semiBlack
    paddingLeft = '2rem'
  }

  return css`
    height: ${height};
    text-align: ${textAlign};
    line-height: ${lineHeight};
    font-size: ${fontSize};
    font-weight: ${fontWeight};
    padding-left: ${paddingLeft};
  `
}

const cardFootoeButtonStyle = (version: string, kind: string) => {
  let backgroundColor
  let width
  if (version === 'old') {
    width = 'auto'
    backgroundColor === color.grayscale.white
  }
  if (version === 'new') {
    width = '100%'
    if (kind === 'dark') {
      backgroundColor = color.grayscale.darkNavyBlue
    }
    if (kind === 'light') {
      backgroundColor = color.grayscale.goldenBrown
    }
  }

  return css`
    background-color: ${backgroundColor};
    width: ${width};
    border-radius: 0 0 ${borders.radius.lg} ${borders.radius.lg};
  `
}

export const CardHeader = styled.div<{ kind: string; version: string }>`
  width: 100%;
  color: ${color.grayscale.white};
  font-family: ${font.family.heading};

  ${(p) => cardHeaderStyle(p.version)}
  ${(p) => cardBackgroundColor(p.version, p.kind)}

  @media (min-width: ${breakpoint.lg}) {
    border-radius: ${borders.radius.lg} 0 0;
    transition: border-radius 0.4s ease-in-out;
  }
`

export const CardInfos = styled.div<{ version: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ version }) => (version === 'old' ? 'center' : 'left')};
  justify-content: flex-start;
  padding: ${({ version }) => (version === 'old' ? `${space.xl} ${space.lg}` : `${space.lg} 0 0 ${space.xl}`)};

  u {
    font-weight: ${font.weight.medium};
  }

  i {
    color: ${color.text.weak};
  }
`

export const CardFooter = styled.div<{ version: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${({ version }) => (version === 'old' ? 'center' : 'left')};
  justify-content: flex-start;
  padding-bottom: ${({ version }) => (version === 'old' ? space.xl : '0')};

  .newObervationText {
    font-size: 0.68rem;
  }
`

export const Card = styled.a<{ version: string; isActive: boolean; kind: string }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 19.6rem;
  margin-right: ${space.lg};
  border-radius: ${borders.radius.lg};
  overflow: hidden;
  transition: box-shadow 0.4s ease-in-out;
  background-color: ${color.grayscale.white};

  @media (min-width: ${breakpoint.lg2}) {
    min-width: auto;
    margin-right: 0;

    ${({ version, isActive, kind }) =>
      version === 'new' &&
      css`
        overflow: visible;
        position: relative;

        ::before {
          display: ${isActive ? 'block' : 'none'};
          content: '';
          position: absolute;
          top: -4%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 0;
          height: 0;
          border-left: 20px solid transparent;
          border-right: 20px solid transparent;
          border-top: ${kind === 'dark'
            ? `20px solid ${color.grayscale.darkNavyBlue}`
            : `20px solid ${color.grayscale.goldenBrown}`};
        }
      `}
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
    background-color: ${color.grayscale.white};
    box-shadow: 0px 0px 50px #1021331a;

    ${CardHeader} {
      border-radius: 0;
    }

    ::before {
      display: ${(props) => (props.isActive ? 'block' : 'block')};
    }
  }
`

export const Description = styled.div`
  display: flex;
  gap: ${space.sm};
  margin: 0 ${space.xs2};
`

export const ButtonWrapper = styled.div<{ version: string; kind: string }>`
  ${(p) => cardFootoeButtonStyle(p.version, p.kind)}

  .buttonNewVersion {
    background-color: transparent;
    border: transparent;
    color: white;
    display: flex;
  }
`
