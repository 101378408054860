import { Auth } from 'aws-amplify'
import { setCookie } from 'nookies'
import { Cookies, ICartCheckoutPayload, ICartPayload, IAddCartItemPayload, ICartSelection } from '@/client/types'
import { api, protectedApi } from '@/client/utils'

export const getCartItems = async (cartId: string) => {
  const url = `/cart/getCart/${cartId}`
  const { data } = await api.get<ICartPayload>(url)
  return data
}

export const addCartItem = async (item: ICartSelection, cartId?: string) => {
  const url = `/cart/addCartItem${cartId ? `/${cartId}` : ''}`
  const { data } = await api.post<IAddCartItemPayload>(url, item)

  if (!cartId) {
    setCookie(null, Cookies.CART_ID, data?._id, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/'
    })
  }
  return data
}

export const addQuoteItemToCart = async (quoteItemId: string, cartId?: string) => {
  const url = `/cart/addQuoteItem/${quoteItemId}${cartId ? `/${cartId}` : ''}`
  const { data } = await api.get<ICartPayload>(url)
  if (!cartId) {
    setCookie(null, Cookies.CART_ID, data?._id, {
      maxAge: 30 * 24 * 60 * 60,
      path: '/'
    })
  }
  return data
}

export const updateCartItem = async (cartId: string, cartItemId: string, payload: Partial<ICartSelection>) => {
  const url = `/cart/${cartId}/cartItem/${cartItemId}`
  const { data } = await api.patch(url, payload)
  return data
}

export const deleteCartItem = async (cartId: string, cartItemId: string) => {
  const url = `/cart/${cartId}/cartItem/${cartItemId}`
  const { data } = await api.delete(url)
  return data
}

export const updateCart = async (cartId: string, payload: Partial<ICartPayload>) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `${attributes['custom:customer_id']}/cart/${cartId}`
  const { data } = await protectedApi.patch(url, payload)
  return data
}

export const getUserCart = async (cartId?: string) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/cart/${attributes['custom:customer_id']}/getCart`
  const { data } = await protectedApi.get<ICartCheckoutPayload>(url)

  setCookie(null, Cookies.CART_ID, data?._id ? data._id : '', {
    maxAge: 30 * 24 * 60 * 60,
    path: '/'
  })

  return data
}

export const checkoutCart = async (cartId: string) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/cart/${attributes['custom:customer_id']}/checkout/${cartId}`
  const { data } = await protectedApi.get<ICartCheckoutPayload>(url)
  return data
}
