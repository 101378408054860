import { FC, memo } from 'react'
import { StarIconProps } from '../types'

export const StarFillIcon: FC<StarIconProps> = memo(({ width, height }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width={width} height={height} viewBox='0 0 26.1 24.9'>
    <path
      fill='#FFB703'
      d='M.7 9.8L6.5 14c1 .7 1.5 2.3 1.1 3.5l-2.2 6.8 5.8-4.2c1-.7 2.6-.7 3.6 0l5.8 4.2-2.2-6.8c-.4-1.2.1-2.7 1.1-3.5l5.8-4.2h-7.2c-1.3 0-2.6-.9-2.9-2.1L13 .9l-2.2 6.8c-.4 1.2-1.7 2.1-2.9 2.1H.7z'
    />
    <path
      fill='transparent'
      d='M20.5 24.9c-.3 0-.6-.1-1-.4l-5.8-4.2c-.4-.3-1.1-.3-1.4 0l-5.8 4.2c-.7.5-1.4.5-1.8 0-.2-.3-.3-.8-.1-1.3l2.2-6.8c.1-.4-.1-1.1-.4-1.3L.6 10.9c-.7-.6-.6-1.2-.6-1.4.1-.3.4-.8 1.4-.8h7.1c.5 0 1-.4 1.1-.8l2.2-6.8c.4-1 1-1.1 1.3-1.1s.8.1 1.2 1.1l2.2 6.8c.1.4.7.8 1.1.8h7.1c1 0 1.3.5 1.4.8.1.3.1.8-.7 1.4l-5.8 4.2c-.4.3-.6.9-.4 1.3l2.2 6.8c.2.6.2 1-.1 1.3-.2.3-.5.4-.8.4zM2.3 10.2l5 3.7c.9.6 1.3 2 1 3l-1.9 5.9 5.1-3.7c.9-.6 2.3-.6 3.2 0l5.1 3.7-1.9-5.9c-.3-1 .1-2.4 1-3l5-3.7h-6.2c-1.1 0-2.2-.8-2.6-1.9l-1.9-5.9-1.9 5.9c-.3 1-1.5 1.9-2.6 1.9H2.3z'
    />
  </svg>
))

StarFillIcon.displayName = 'StarFillIcon'
