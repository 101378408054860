import { useMutation, useIsMutating } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { updateArtworkAssistant } from '@/infra/services/artworkAssistant'

export const useMutateArtworkAssistant = () => {
  const isLoading = useIsMutating([StorageKey.ARTWORK_ASSISTANT])

  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.ARTWORK_ASSISTANT],
    mutationFn: (data: {}) => updateArtworkAssistant(data)
  })

  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
