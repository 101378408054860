import { FC } from 'react'
import * as S from './styles'
import { ILoader } from './types'

export const Loader: FC<ILoader> = ({ type = 'spinner', size = 'large', color = 'primary', wrapper }) => {
  const types = {
    spinner: <S.Spinner size={size} color={color} />,
    price: <S.PriceLoader color={color} />
  }

  const Component = types[type]

  return wrapper ? (
    <S.Wrapper className='loader-wrapper-block' wrapper={wrapper}>
      {Component}
    </S.Wrapper>
  ) : (
    Component
  )
}
