import { useQuery } from '@tanstack/react-query'
import { useRouter } from 'next/router'
import { StorageKey } from '@/client/types'
import { searchPages } from '@/infra/services'

export const useSearchPages = (value?: string) => {
  const { locale } = useRouter()

  const { data, isFetching } = useQuery([StorageKey.SEARCH, locale, value], () => searchPages(locale || 'en', value), {
    enabled: !!value
  })

  return { data, isFetching }
}
