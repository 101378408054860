import styled, { css } from 'styled-components'
import { tokens } from '../theme'
const { color, font, space } = tokens

const BasicStyle = css`
  font-family: ${font.family.text};
  font-size: ${font.size.root};
  line-height: ${font.lineHeight.lg};
  margin-bottom: ${space.xl2};

  strong {
    font-weight: ${font.weight.medium};
  }

  h1 {
    font-size: ${font.size.h4};
  }

  h2 {
    font-size: ${font.size.h5};
  }

  h3 {
    font-size: ${font.size.h6};
  }

  h4,
  h5 {
    font-size: ${font.size.root};
  }

  img {
    display: block;
    max-width: 100%;
    height: auto;
    margin-bottom: ${space.lg};
  }

  ul {
    list-style: disc;
  }
`

export const Content = styled.div<{ inverse: boolean }>`
  ${BasicStyle}
  color: ${(p) => (p.inverse ? color.text.inverse : color.text.weak)};

  b,
  a {
    color: ${(p) => (p.inverse ? color.text.inverse : color.text.primary)};
    font-weight: ${(p) => (p.inverse ? font.weight.bold : font.weight.normal)};
  }

  strong,
  h1,
  h2,
  h3,
  h4,
  h5 {
    color: ${(p) => (p.inverse ? color.text.inverse : color.text.normal)};
  }
`
