import styled, { css } from 'styled-components'
import { Kinds, FieldsetType } from './types'
import { tokens } from '../theme'
const { borders, breakpoint, color, space } = tokens

export const Fieldset = styled.fieldset<{ kind: Kinds; newVersion: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 2fr;
  margin-bottom: ${(p) => (p.newVersion ? space.md : space.lg)};
  padding: 0;
  border: none;

  ${({ kind }) =>
    kind === 'column' &&
    `
    @media (min-width: ${breakpoint.lg}) {
      grid-template-columns: 2fr 5fr;
      grid-template-rows: 1fr;
      gap: ${space.lg};
    }
  `}
`

export const Legend = styled.div<{ kind: Kinds; fieldsetType: FieldsetType; newVersion: boolean }>`
  background-color: ${({ fieldsetType, newVersion }) =>
    !newVersion && (fieldsetType === 'accordian' ? color.grayscale.light : color.primary.darker)};

  .noLegend & {
    padding: 0.25rem 0;
  }

  @media (min-width: ${breakpoint.lg}) {
    ${({ kind, newVersion }) =>
      kind === 'column' &&
      !newVersion &&
      `
      border-radius: ${borders.radius.lg};
      
    `}
  }

  ${(p) =>
    p.newVersion
      ? css`
          padding: ${space.xs} 0;
        `
      : css`
          padding: ${space.lg};
          border-radius: ${borders.radius.lg} ${borders.radius.lg} 0 0;

          @media (min-width: ${breakpoint.lg}) {
            padding: ${space.xl};
          }
        `};
`

export const Fields = styled.div<{ kind: Kinds; fieldsetType: FieldsetType; newVersion: boolean }>`
  @media (min-width: ${breakpoint.lg}) {
    ${({ kind, newVersion }) =>
      kind === 'column' &&
      !newVersion &&
      `
      border-radius: ${borders.radius.lg};
    `}
  }

  @media (min-width: ${breakpoint.xs}) {
    ${({ fieldsetType, newVersion }) =>
      fieldsetType === 'accordian' &&
      !newVersion &&
      `
      padding: 0
    `}
  }
  ${(p) =>
    p.newVersion
      ? css``
      : css`
          padding: ${space.lg};
          background-color: ${color.grayscale.lighter};
          border-radius: 0 0 ${borders.radius.lg} ${borders.radius.lg};
          @media (min-width: ${breakpoint.lg}) {
            padding: ${space.xl};
          }
        `};
`
