export const decodeInfos = (data, form) => {
  const newForm = { ...form }

  for (const [key, value] of Object.entries(data)) {
    newForm[key] = {
      ...newForm[key],
      hasError: !value,
      value
    }
  }

  return newForm
}
