import { protectedApi, api } from '@/client/utils/api'
import {
  GetFileResult,
  GetJobInfo,
  GetPreview,
  SaveUploadFiles,
  SubmitUploadFiles as FinalizeUploadFiles,
  UploadFile
} from './types'

export const getJobInfo: GetJobInfo = async (cID, job) => {
  const url = `/${cID}/upload/jobinfo/${job}`
  const { data } = await protectedApi.get(url)
  return data
}

export const uploadFile: UploadFile = async ({ file, signal, props }) => {
  const urlParams = new URLSearchParams()
  Object.entries(props).forEach((param) => urlParams.set(param[0], String(param[1])))

  urlParams.set('name', String(file.name))
  urlParams.set('size', String(file.size))
  urlParams.set('currentChunkIndex', String(file.index))
  urlParams.set('totalChunks', String(file.length))

  const url = `/dataupload_test/preflight`
  const fullUrl = url + '?' + urlParams.toString()

  const { data } = await api.post(fullUrl, file.base64, { signal })
  return data
}

export const uploadChunkFiles: any = async (chunkDataSet): Promise<any> => {
  const formData = new FormData()
  formData.append('file', chunkDataSet.chunk, chunkDataSet.filename) // Append the chunk with type
  formData.append('filename', chunkDataSet.filename)
  formData.append('total_chunks', chunkDataSet.totalChunks)
  formData.append('chunk_index', chunkDataSet.chunkIndex)

  const params = {
    file: chunkDataSet.chunk,
    filename: chunkDataSet.filename,
    chunk_index: chunkDataSet.chunkIndex,
    total_chunks: chunkDataSet.totalChunks,
    width: chunkDataSet.width,
    height: chunkDataSet.height,
    fileProps: chunkDataSet.fileConf
  }

  const url = `/customer/${params.fileProps.customer_id}/customerProjectFolder/${params.fileProps.page}/customerFile`
  const progress = {
    total: 0
  }
  const { data } = await protectedApi.post(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      //   (progress.total =
      //     chunkDataSet.index * chunkDataSet.chunkSize >= chunkDataSet.totalChunks
      //       ? 100
      //       : (chunkDataSet.chunkIndex / chunkDataSet.totalChunks) * 100)
      // return (chunkDataSet.chunkIndex / chunkDataSet.totalChunks) * 100
      if (progressEvent && progressEvent.progress === 1) {
        return (progress.total = (chunkDataSet.chunkIndex / (chunkDataSet.totalChunks - 1)) * 100)
      } else {
        return false
      }
    }
  })
  return { data, progress: progress.total }
}

export const getPreview: GetPreview = async (props) => {
  const urlParams = new URLSearchParams()

  urlParams.set('job', String(props.job))
  urlParams.set('filename', String(props.filename))
  urlParams.set('page', String(props.page))

  const url = `/dataupload_test/preview`
  const fullUrl = url + '?' + urlParams.toString()

  const { data } = await api.get(fullUrl)
  return data
}

export const getFileResult: GetFileResult = async (preflight_hash, page, width = 0, height = 0) => {
  const url = `/dataupload/preflight/${preflight_hash}/${page}?width=${width}&height=${height}`
  const { data } = await api.get(url)
  return data
}

export const saveUploadFiles: SaveUploadFiles = async ({ job, files }) => {
  const urlParams = new URLSearchParams()
  urlParams.set('order', job)
  const url = `/dataupload_test/sorts`
  const fullUrl = url + '?' + urlParams.toString()
  const { data } = await api.post(fullUrl, files)
  return data
}

export const finalizeUploadFiles: FinalizeUploadFiles = async (job) => {
  const urlParams = new URLSearchParams()
  urlParams.set('job', job)
  const url = `/dataupload_test/finalize`
  const fullUrl = url + '?' + urlParams.toString()
  const { data } = await api.post(fullUrl)
  return data
}
