import React, { FC, memo } from 'react'
import * as S from './styles'
import { InputProps } from './types'
import { Icon } from '../Icon'

export const SelectRadioBasic: FC<InputProps> = memo(
  ({ id, name, value, label, onChange, checked, defaultChecked, rate }) => (
    <S.InputWrapper>
      <S.InputBox>
        <input
          name={name}
          type='radio'
          data-checked={checked}
          value={value}
          onChange={onChange}
          defaultChecked={defaultChecked}
        />
        <div className='label-circle'></div>
        <label>{label}</label>
      </S.InputBox>
      <S.PriceLabel>
        <label>{rate}</label>
      </S.PriceLabel>
    </S.InputWrapper>
  )
)

SelectRadioBasic.displayName = 'SelectRadioBasic'
