import styled, { css } from 'styled-components'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'

export const Collapse = styled.div`
  width: 100%;
`

export const CollapseItem = styled.div<{ kind: string; open: boolean }>`
  overflow: hidden;

  ${({ kind, open }) =>
    kind === 'modern'
      ? css`
          margin-bottom: ${space.sm};
          border-radius: ${borders.radius.lg};
          background-color: ${open ? color.grayscale.white : color.grayscale.light};
          box-shadow: ${open ? '0px 0px 30px #1021331A' : 'none'};
        `
      : css`
          &:not(:last-of-type) {
            border-bottom: ${borders.sizes.md} dashed ${color.grayscale.light};
          }
        `}
`

export const CollapseHeader = styled.button<{ open: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: ${space.xl};
  border: none;
  outline: none;
  background-color: transparent;
  color: ${({ open }) => (open ? color.text.primary : color.text.normal)};
  font-family: ${font.family.heading};
  font-size: ${font.size.h6};
  font-weight: ${font.weight.medium};
  text-align: left;

  svg {
    margin-left: ${space.sm};
    min-width: 24px;
  }
`

export const CollapseBody = styled.div`
  transition: height 0.4s ease;
`

export const CollapseBodyChildren = styled.div`
  padding: 0 ${space.xl} ${space.xl};
`
