import { FC, useEffect, useState, memo, useRef } from 'react'
import ImageNext, { ImageProps, StaticImageData } from 'next/image'
import { useWindowSize } from '@/client/hooks/windowSize'
import { FeaturedImage } from '@/client/types/Image'
import * as S from './styles'
import { useGetImageProxy } from '@/client/hooks/proxyImageElement'
import { protectedApi } from '@/client/utils'
import { Button } from '../Button'
type ImageComponentType = Partial<ImageProps> & {
  hasBreakpoint?: boolean
  imageData?: FeaturedImage
  src?: string | StaticImageData
  alt?: string
  page?: number
  proxyPreivew?: boolean
  showPreview: (index: number) => void
  index: number
  isOpen: boolean
}

export const ProxyImage: FC<ImageComponentType> = ({
  hasBreakpoint,
  imageData,
  src,
  width,
  height,
  alt,
  title,
  page,
  proxyPreivew,
  index,
  showPreview,
  isOpen,
  ...rest
}) => {
  const popupRef = useRef<HTMLDivElement | null>(null) // Ref for the popup
  const { breakpoint } = useWindowSize()
  const [imageError, setImageError] = useState(false)
  const [imageSrc, setImageSrc] = useState('')
  const [preview, setPreview] = useState(false)
  const {
    data: previewImageData,
    isLoading,
    refetch: refetchImage,
    remove,
    isSuccess: isImageSucess
  } = useGetImageProxy(src)

  useEffect(() => {
    refetchImage()
  }, [src])

  useEffect(() => {}, [proxyPreivew])

  useEffect(() => {
    if (previewImageData) {
      setImageSrc(previewImageData)
      remove()
    }

    return () => {
      setPreview(false)
    }
  }, [previewImageData])

  const getCurrentBreakpoint = () => {
    const breakpointValue = ['desktop', 'mobile', 'tablet'].forEach((value) => {
      if (!imageData) return 'desktop'

      const hasImage = Boolean(imageData[value]?.url)

      if (!hasImage && value === 'mobile') return 'tablet'
      if (!hasImage && value === 'tablet') return 'desktop'
      if (!hasImage && value === 'desktop') return 'tablet'
    })

    return breakpointValue
  }

  if (hasBreakpoint && breakpoint && imageData) {
    const breakpointVerified = (imageData[breakpoint]?.url ? breakpoint : getCurrentBreakpoint()) || 'desktop'

    return (
      <ImageNext
        src={imageData[breakpointVerified].url}
        width={imageData[breakpointVerified].width}
        height={imageData[breakpointVerified].height}
        alt={imageData[breakpointVerified].alt || imageData[breakpointVerified].title}
        {...rest}
      />
    )
  }

  if (imageError) {
    return (
      <S.ImageNextErrorWrapper>
        <img src='/img/my-account/no-image.png' alt='Preview no image' style={{ maxWidth: '100%' }} />
      </S.ImageNextErrorWrapper>
    )
  }
  if (src) {
    return (
      <S.ImageNextWrapper ref={popupRef} className='popup'>
        {imageSrc ? (
          <img src={imageSrc} onClick={() => showPreview(index)} />
        ) : (
          <img
            src='/img/my-account/no-image.png'
            id='no-image-element'
            alt='Preview no image'
            style={{ maxWidth: '100%' }}
          />
        )}
        {isOpen && (
          <S.ImagePreview>
            <img src={imageSrc} />
          </S.ImagePreview>
        )}
      </S.ImageNextWrapper>
    )
  }

  return <></>
}
