import styled, { css, keyframes, FlattenSimpleInterpolation } from 'styled-components'
import { ILoaderColor, ILoaderSize } from './types'
import color from '../theme/color'

export const Wrapper = styled.div<{ eLoad: boolean }>`
  display: ${(p) => (p.eLoad ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(218, 221, 223, 0.7);
  z-index: 999;
`
export const LoadSet = styled.div`
  text-align: center;
`
