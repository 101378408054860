import { CART_ACTIONS } from './constants'

export const cartReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case CART_ACTIONS.SET_SELECTION:
      return {
        ...state,
        selection: payload.selection
      }

    default:
      throw new Error('No case for that type')
  }
}
