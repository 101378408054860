import { LABELLIBRARY_ACTIONS } from './constants'

export const LabelLibraryReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case LABELLIBRARY_ACTIONS.ADD_TO_COPY_SELECTION:
      return {
        ...state,
        copy_selection: payload
      }
    case LABELLIBRARY_ACTIONS.SET_CURRENT_FOLDER:
      return {
        ...state,
        currentFolder: payload
      }
    case LABELLIBRARY_ACTIONS.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload
      }
    case LABELLIBRARY_ACTIONS.SET_TOTAL_PAGES:
      return {
        ...state,
        totalPage: payload
      }
    case LABELLIBRARY_ACTIONS.SET_LOADER:
      return {
        ...state,
        loadingStatus: payload
      }
    case LABELLIBRARY_ACTIONS.SET_MESSAGES:
      return {
        ...state,
        messages: payload
      }
    case LABELLIBRARY_ACTIONS.SET_UPLOAD_MODAL:
      return {
        ...state,
        uploadModal: payload
      }
    case LABELLIBRARY_ACTIONS.SET_CHANGES_ON_FILE:
      return {
        ...state,
        changesOnFile: payload
      }
    case LABELLIBRARY_ACTIONS.SET_CHANGES_ON_FOLRDERS:
      return {
        ...state,
        changesOnFolder: payload
      }
    case LABELLIBRARY_ACTIONS.SET_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: payload
      }
    case LABELLIBRARY_ACTIONS.SET_CURRENT_FOLDER_NAME:
      return {
        ...state,
        currentFolderName: payload
      }
    case LABELLIBRARY_ACTIONS.SET_ORDER_NO:
      return {
        ...state,
        selectedOrderNumber: payload
      }
    default:
      throw new Error('No case for that type')
  }
}
