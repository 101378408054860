const currency = (value: number, lang = 'de-DE', currency = 'EUR') =>
  new Intl.NumberFormat(lang, {
    style: 'currency',
    currency
  })
    .format(value)
    .replace('\xa0', ' ')

export const percentage = (value: string | number, digits = 0) => {
  const num = Number(value)
  if (Number.isNaN(num)) {
    return 'NaN'
  }

  return num.toLocaleString('en-US', { style: 'percent', minimumFractionDigits: digits })
}

const date = (rawDate, options = {}) => {
  if (!rawDate || typeof rawDate !== 'string') {
    return ''
  }

  let newDate = rawDate
  if (typeof rawDate === 'string') {
    newDate = rawDate.replace(/-/g, '/')
  }
  const standardDate = new Date(newDate)
  return new Intl.DateTimeFormat('pt-BR', options).format(new Date(standardDate.getTime()))
}

const email = (name, value) => {
  let newValue = value

  if (typeof name !== 'string' || typeof value !== 'string') {
    return ''
  }

  if (!name || !value) {
    return ''
  }

  if (name === 'email' || name === 'email_confirmation') {
    newValue = newValue.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    newValue = newValue.replace(/[,():<>]/g, '')
  }

  return newValue
}

const phone = (value) => {
  if (typeof value !== 'string') {
    return ''
  }

  const newValue = value.replace(/\D/g, '').substring(0, 11)
  let formattedValue

  const size = newValue.length
  if (size === 0) {
    formattedValue = newValue
  }

  if (size > 0 && size < 3) {
    formattedValue = `(${newValue}`
  }

  if (size >= 3 && size < 7) {
    formattedValue = `(${newValue.substring(0, 2)}) ${newValue.substring(2, 6)}`
  }

  if (size >= 7 && size < 11) {
    formattedValue = `(${newValue.substring(0, 2)}) ${newValue.substring(2, 6)}-${newValue.substring(6, 10)}`
  }

  if (size === 11) {
    formattedValue = `(${newValue.substring(0, 2)}) ${newValue.substring(2, 7)}-${newValue.substring(7, 11)}`
  }

  return formattedValue
}

export const format = {
  currency,
  percentage,
  date,
  email,
  phone
}
