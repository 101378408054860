import { FC, memo, useEffect, useState, useCallback, useContext } from 'react'
import { IconSizes } from '@/client/components/Icon/types'
import { Row, Col, Breadcrumb, Checkbox, Input, Loader } from '@/client/components'
import * as S from './styles'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { useClearQuery, useDebounce, useSearchPages } from '@/client/hooks'
import { LabelLibraryContext } from '@/client/context/labelLibrary'
const isObjectEmpty = (obj: { [key: string]: string }) => Object.keys(obj).length === 0 && obj.constructor === Object

const iconCloseSize: Record<string, IconSizes> = {
  small: 's3',
  medium: 's5',
  large: 's6',
  full: 's7'
}

export const MultiTableSearch: FC<any> = memo(({ content, search, searchValue }) => {
  const { mutate: clearQuery } = useClearQuery()
  /**
   *
   * Context for Label Library
   *
   */
  const { setSearchKeyword } = useContext(LabelLibraryContext)

  const [isClickedOutside, setClickedOutside] = useState(false)
  return (
    <S.MultiTableSerchWrapper>
      <Row isFull>
        <Col noGutter>
          <S.Search role='button' onClick={() => setClickedOutside(false)}>
            <S.SearchInput $active={true}>
              <Input
                isBlock
                id='input-search'
                name='p'
                type='search'
                placeholder={content['table_header']['search_bar']}
                onChange={(e) => {
                  setSearchKeyword(e.currentTarget.value.toLocaleLowerCase())
                  searchValue(e.currentTarget.value.toLocaleLowerCase())
                }}
                className='table-search'
                autoComplete='off'
                rightElement={
                  // <S.SearchIcon>{isFetching ? <Loader size='small' /> : <Icon name='search' size='s5' />}</S.SearchIcon>
                  <S.SearchIcon>{<Icon name='search' size='s5' />}</S.SearchIcon>
                }
              />
            </S.SearchInput>
          </S.Search>
        </Col>
      </Row>
    </S.MultiTableSerchWrapper>
  )
})

MultiTableSearch.displayName = 'MultiTableSearch'
