import { FC, useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { Input } from '@/client/components/Input'
import { Props } from './types'

export const ControlledInput: FC<Props> = ({ name, formatter, registerOptions, forceError, ...rest }) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors }
  } = useFormContext()

  const watcher = watch(name)
  useEffect(() => {
    formatter && setValue(name, formatter(watcher || ''))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watcher])

  const errorMessage = errors?.[name]?.message

  return (
    <Input
      hasError={!!errorMessage || forceError}
      error={errorMessage && String(errorMessage)}
      register={register(name, registerOptions)}
      {...rest}
    />
  )
}
