import styled from 'styled-components'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'
import zindex from '../theme/zindex'

const positionStyle = (top: number, left: number) => {
  if (top && left) {
    return `
      position: absolute;
      top: ${top}px;
      left: ${left}px;
      transform: translate(-50%, -102%);
    `
  }

  return `
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `
}

export const Onboarding = styled.div<{ top: number; left: number }>`
  z-index: ${zindex.modal};
  width: 24rem;
  height: auto;
  padding: ${space.xl} ${space.lg} ${space.lg};
  border-radius: ${borders.radius.lg};
  background-color: ${color.grayscale.white};
  border: ${borders.sizes.sm} solid ${color.grayscale.light};
  box-shadow: 0px 0px 26px -8px #c4c4c4;
  ${({ top, left }) => positionStyle(top, left)}
`

export const Close = styled.button`
  position: absolute;
  top: ${space.md};
  right: ${space.md};
  height: ${space.md};
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${color.grayscale.dark};
  font-size: ${font.size.h4};
  line-height: ${space.sm};
  font-weight: 100;
`

export const Title = styled.p`
  font-size: ${font.size.root};
  font-weight: ${font.weight.medium};
  line-height: ${font.lineHeight.md};
`

export const Paragraph = styled.p`
  font-size: ${font.size.small};
  line-height: ${font.lineHeight.md};
`

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const ArrowButton = styled.button`
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    transition: all 0.4s ease;
  }

  &:hover {
    .icon {
      color: ${color.primary.pure};
    }
  }
`

export const Dots = styled.ul`
  display: flex;
  align-items: center;
  gap: ${space.xs2};
  margin: 0;
  padding: 0;
`

export const Dot = styled.li<{ active: boolean }>`
  width: ${space.xs};
  height: ${space.xs};
  border-radius: ${borders.radius.md};
  background-color: ${({ active }) => (active ? color.primary.pure : color.grayscale.gray)};
`

export const Mask = styled.div`
  opacity: 0.7;
  left: 0;
  top: 0;
  position: fixed;
  z-index: ${zindex.modalOverlay};
  pointer-events: none;
  color: #000;
`

export const SvgWrapper = styled.svg`
  position: fixed;
`

export const MaskArea = styled.rect`
  ${({ x, y, width, height }) => `
    x: ${x}px;
    y: ${y}px;
    width: ${width}px;
    height: ${height}px;
  `}
`
