import React, { FC } from 'react'
import * as S from './styles'
import { TabProps } from './types'
import { Icon, Text } from '../'

export const Tabs: FC<TabProps> = ({ steps, stepActive, price, resume, action, kind, isDisabled, serviceType }) => {
  const totalSteps = steps.length

  let newStep

  const handleLeftClick = () => {
    switch (true) {
      case serviceType === 'basic' && stepActive === 8:
        newStep = Math.max(1, stepActive - 5)
        break
      case serviceType === 'premium' && stepActive === 8:
        newStep = Math.max(1, stepActive - 3)
        break
      case serviceType === 'smart' && stepActive === 8:
        newStep = Math.max(1, stepActive - 5)
        break
      case serviceType === 'pro' && stepActive === 8:
        newStep = Math.max(1, stepActive - 4)
        break
      default:
        newStep = Math.max(1, stepActive - 1)
        break
    }

    action && action(newStep)
  }

  const handleRightClick = () => {
    switch (true) {
      case serviceType === 'basic' && stepActive === 3:
        newStep = stepActive + 5
        break
      case serviceType === 'premium' && stepActive === 5:
        newStep = stepActive + 3
        break
      case serviceType === 'smart' && stepActive === 3:
        newStep = stepActive + 5
        break
      case serviceType === 'pro' && stepActive === 4:
        newStep = stepActive + 4
        break
      default:
        newStep = Math.min(totalSteps, stepActive + 1)
        break
    }

    action && action(newStep)
  }

  return (
    <S.TopBar>
      <S.StepsWrapper>
        <S.Steps>
          {kind === 'withIcon' && (
            <>
              <S.LeftButton
                active={stepActive === 1}
                onClick={handleLeftClick}
                id={stepActive !== 1 ? 'calc_step_p' + `${stepActive - 1}` : 'calc_step_p'}
              >
                <Icon name='slide-left' size='s5' />
              </S.LeftButton>
              <S.RightButton
                finished={stepActive === steps.length}
                isDisabled={isDisabled}
                onClick={handleRightClick}
                id={'calc_step_n' + stepActive}
              >
                <Icon name='slide-right' size='s5' />
              </S.RightButton>
            </>
          )}
          {steps.map((step, index) => (
            <S.Step
              key={step.id}
              active={stepActive === step.key}
              finished={stepActive > step.key}
              before={stepActive - 1 === step.key}
              after={stepActive + 1 === step.key}
              initial={index === stepActive + 1}
              onClick={() => action && action(step.key)}
              id={'calc_' + step.id}
            >
              {index !== 0 && <S.NextIcon name='chevron-right' />}
              {stepActive < step.key && <S.StepNumber active={stepActive === step.key}>{''}</S.StepNumber>}
              {stepActive >= step.key && (
                <S.StepNumber active finished={stepActive > step.key}>
                  {stepActive > step.key ? <Icon name='checkmark' /> : '...'}
                </S.StepNumber>
              )}
              {step.name}
            </S.Step>
          ))}
        </S.Steps>
        <S.TopCalculatorGradient />
      </S.StepsWrapper>
      {price && (
        <S.TopCalculatorPrice>
          <Text size='xlg' color='primary-light' family='heading' weight='bold' align='right' margin='0'>
            {price}
          </Text>
          <Text size='sm' color='weak' align='right' margin='0' italic>
            {resume}
          </Text>
        </S.TopCalculatorPrice>
      )}
    </S.TopBar>
  )
}
