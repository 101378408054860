export const assertValidEmail = (value, errorString?) => {
  const regexEmail =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  let string = errorString ? errorString : 'This email is invalid'
  return !regexEmail.test(String(value).toLowerCase()) ? string : ''
}

export const assertMatchFields = (value, matchValue) => (value !== matchValue ? 'Both fields must be the same' : '')

export const assertMinlength = (value, min, errorString?) => {
  let string = errorString ? errorString : `It must contain at least ${min} characters`
  return value.length < Number(min) ? string : ''
}

export const assertMaxlength = (value, max) =>
  value.length > Number(max) ? `It must contain at most ${max} characters` : ''

export const assertRequiredField = (value, errorString?) => {
  let string = errorString ? errorString : 'This field is required'
  return !value ? string : ''
}

export const assertStrongPassword = (value) => {
  const cognitoPasswordPolicy =
    /^(?!\s+)(?!.*\s+$)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9$^*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/
  const testPolicy = cognitoPasswordPolicy.test(value)

  return testPolicy
    ? ''
    : 'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character'
}

export const assertCpf = (cpf) => {
  let add
  let i
  let rev
  const cleanValue = cpf.replace(/[^\d]+/g, '')
  const invalidList = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999'
  ]

  if (cleanValue === '' || cleanValue.length !== 11 || invalidList.indexOf(cleanValue) !== -1) {
    return 'Este CPF é inválido'
  }
  // Elimina CPFs invalidos conhecidos
  // Valida 1o digito
  add = 0
  for (i = 0; i < 9; i += 1) {
    add += parseInt(cleanValue.charAt(i), 10) * (10 - i)
  }

  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }

  if (rev !== parseInt(cleanValue.charAt(9), 10)) {
    return 'Este CPF é inválido'
  }
  // Valida 2o digito
  add = 0
  for (i = 0; i < 10; i += 1) {
    add += parseInt(cleanValue.charAt(i), 10) * (11 - i)
  }

  rev = 11 - (add % 11)
  if (rev === 10 || rev === 11) {
    rev = 0
  }

  if (rev !== parseInt(cleanValue.charAt(10), 10)) {
    return 'Este CPF é inválido'
  }

  return false
}

export const assertCnpj = (cnpj) => {
  const cleanValue = cnpj.replace(/[^\d]+/g, '')
  if (cleanValue.length < 14 && cleanValue.length < 15) {
    return 'Este CNPJ é inválido'
  }

  let i
  let digitos_iguais = 1

  for (i = 0; i < cleanValue.length - 1; i += 1) {
    if (cleanValue.charAt(i) !== cleanValue.charAt(i + 1)) {
      digitos_iguais = 0
      break
    }
  }
  if (digitos_iguais) {
    return 'Este CNPJ é inválido'
  }

  let tamanho = cleanValue.length - 2
  let numeros = cleanValue.substring(0, tamanho)
  const digitos = cleanValue.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7
  for (i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos
    pos -= 1

    if (pos < 2) {
      pos = 9
    }
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(0), 10)) {
    return 'Este CNPJ é inválido'
  }

  tamanho += 1
  numeros = cleanValue.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7

  for (i = tamanho; i >= 1; i -= 1) {
    soma += numeros.charAt(tamanho - i) * pos
    pos -= 1

    if (pos < 2) {
      pos = 9
    }
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)

  if (resultado !== parseInt(digitos.charAt(1), 10)) {
    return 'Este CNPJ é inválido'
  }

  return false
}
