import React, { FC, memo } from 'react'
import * as S from './styles'
import { AvatarProps } from './types'
import { Image } from '..'

export const Avatar: FC<AvatarProps> = memo(
  ({ size = 'md', image, alt, width, height, hasBorder = false, ...rest }) => (
    <S.Wrap size={size} hasBorder={hasBorder} {...rest}>
      <Image src={image} alt={alt} width={width || S.getSize[size]} height={height || S.getSize[size]} />
    </S.Wrap>
  )
)

Avatar.displayName = 'Avatar'
