import { useReducer, useCallback } from 'react'
import { CartContext } from './CartContext'
import { CART_ACTIONS } from './constants'
import { cartReducer } from './reducer'

export const useValues = () => {
  const [state, dispatch] = useReducer(cartReducer, { selection: null })

  const updateSelection = useCallback((selection) => {
    dispatch({
      type: CART_ACTIONS.SET_SELECTION,
      payload: {
        selection: selection
      }
    })
  }, [])

  return {
    selection: state.selection,
    updateSelection
  }
}

export const CartProvider = ({ children }) => {
  const value = useValues()
  return <CartContext.Provider value={value}>{children}</CartContext.Provider>
}
