import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { IAddress, ICompleteAddress, StorageKey } from '@/client/types'
import { getUserAddresses, createUserAddress, updateUserAddress, isActiveAddress } from '@/infra/services'
import { protectedApi } from '@/client/utils'

type argsMutate = {
  endpoint: string
  address: IAddress
}

export const useGetUserAddresses = () =>
  useQuery(['userAddresses'], () => getUserAddresses(), { refetchOnWindowFocus: false })

export const useMutateCreateUserAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ endpoint, address }: argsMutate) => createUserAddress(endpoint, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.USER_ADDRESS] })
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['addressErrorResult'] })
    }
  })
}

export const useMutateUpdateUserAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ endpoint, address }: argsMutate) => updateUserAddress(endpoint, address),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [StorageKey.USER_ADDRESS] })
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['addressEditErrorResult'] })
    }
  })
}

/**
 * Retrieve the active status of the address
 */
export const useMutateIsActiveAddress = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (address: any) => isActiveAddress(address),
    onSuccess: (data) => {
      return data
    },
    onError: () => {
      queryClient.invalidateQueries({ queryKey: ['isActiveAddresses'] })
    }
  })
}
