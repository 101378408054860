import styled, { css } from 'styled-components'
import { DynamicKeys } from '@/client/types'
import { TagStyleProps } from './types'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'

const getSize: DynamicKeys = {
  default: css`
    font-size: 0.75rem;
    line-height: 1.4rem;
    padding: 0.25rem 1rem;
  `,
  large: css`
    font-size: ${font.size.root};
    line-height: ${font.lineHeight.md};
    padding: ${space.sm} ${space.lg};
  `
}

const getKind = (active: boolean) => ({
  default: css`
    background-color: ${color.neutral.pure};
    color: ${color.grayscale.black};
  `,
  filter: css`
    border: 2px solid ${color.grayscale.light};
    background-color: ${color.grayscale.white};
    color: ${color.text.normal};
    font-weight: ${font.weight.medium};
    transition: all 0.3s ease;
    cursor: pointer;

    ${active &&
    css`
      border-color: ${color.primary.dark};
      background-color: ${color.primary.dark};
      color: ${color.text.inverse};
    `}

    &:hover {
      border-color: ${color.primary.dark};
      background-color: ${color.primary.dark};
      color: ${color.text.inverse};
    }
  `
})

export const Tag = styled.span<TagStyleProps>`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  border-radius: ${borders.radius.pill};
  text-align: center;
  white-space: nowrap;
  ${(p) => getSize[p.size]};
  ${(p) => getKind(p.active)[p.kind]}

  &:not(:last-of-type) {
    margin-right: ${space.xs};
  }

  &:before {
    content: '';
    display: block;
    height: 0.0625rem;
    margin-bottom: -0.125rem;
  }
`
