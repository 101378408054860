/*
 *
 * DS => Design Serive
 *
 *
 * */
export enum DS_ACTIONS {
  SET_DS_SELECTION = 'SET_DS_SELECTION',
  SET_DS_PRICES = 'SET_DS_PRICES',
  SET_DS_BASIC_FIELD_RESPONSE = 'SET_DS_BASIC_FIELD_RESPONSE',
  SET_STEP = 'SET_STEP'
}
