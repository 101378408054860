import styled, { css } from 'styled-components'
import * as types from './types'
import borders from '../theme/borders'
import breakpoint from '../theme/breakpoint'
import color from '../theme/color'
import space from '../theme/space'
import { CheckboxStyleProps } from '../Checkbox/types'

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const IconWrapper = styled.span<CheckboxStyleProps>`
  position: relative;
  justify-content: center;
  align-items: center;
  width: 1.7rem;
  min-width: 1.7rem;
  height: 1.7rem;
  margin-right: ${space.xs};
  left: -${space.xs};

  svg {
    max-width: 100%;
    max-height: 100%;
  }

  .inner-shape-class {
    border-color: ${(p) => (p.checked ? 'black' : 'white')};
  }

  .shape-class {
    border-color: ${(p) => (p.checked ? color.grayscale.primary : color.grayscale.gray)};
    background-color: white;
  }

  @media (min-width: ${breakpoint.lg}) {
    margin-right: ${space.md};
  }
`

export const Infos = styled.span`
  display: flex;
  flex-wrap: wrap;
`

export const Hint = styled.sup`
  margin-left: ${space.xs2};
  cursor: help;
`

const getCheckedStyle = (checked: boolean, backgroundType: types.BackgroundType) => {
  if (checked) {
    return css`
      background-color: ${backgroundType === 'white' ? color.grayscale.white : color.grayscale.lighter};
      border: ${borders.sizes.md} solid ${color.primary.darker};
      padding: ${space.sm} 1.25rem;

      svg {
        fill: ${color.primary.darker};
      }
    `
  }

  return css`
    background-color: ${color.grayscale.white};
    padding: ${space.sm} 1.25rem;
    border: ${borders.sizes.md} solid ${color.grayscale.light};

    svg {
      fill: ${color.grayscale.dark};
    }
  `
}

const cssDisabled = css`
  opacity: 0.4;
`

const getOrientation: types.GetOrientationProps = {
  horizontal: css`
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  `,
  vertical: css`
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;

    ${IconWrapper} {
      width: 5.5rem;
      height: 5.5rem;
      margin-bottom: ${space.sm};
    }
  `
}

export const SelectCard = styled.label<types.SelectCardStyleProps>`
  background: ${(p) => (p.giveBackground ? `url(${p.giveBackground})` : '#ffff')};
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-self: stretch;
  width: 9rem;
  height: auto;
  min-height: 1rem;
  border-radius: ${borders.radius.lg};
  cursor: pointer;
  transition: background-color 0.3s ease;
  ${({ checked, backgroundType }) => getCheckedStyle(checked, backgroundType)};
  ${({ disabled }) => disabled && cssDisabled};
  ${({ orientation }) => getOrientation[orientation]};

  .topHint {
    left: 6rem;
  }

  @media (min-width: ${breakpoint.lg}) {
    width: 13rem;
    ${({ orientation }) => orientation === 'vertical' && 'width: 10rem'};
  }
`
