import React, { FC, memo } from 'react'
import * as S from './styles'
import { HorizontalScrollProps } from './types'

export const HorizontalScroll: FC<HorizontalScrollProps> = memo(({ children, ...rest }) => (
  <S.HorizontalScroll {...rest}>
    <S.HorizontalScrollInner>{children}</S.HorizontalScrollInner>
  </S.HorizontalScroll>
))

HorizontalScroll.displayName = 'HorizontalScroll'
