import React, { FC, memo } from 'react'
import * as S from './styles'
import { SelectProps } from './types'

export const Select: FC<SelectProps> = memo(
  ({
    id,
    label = '',
    required = false,
    error = '',
    hasError = false,
    isBlock = false,
    isInline = false,
    disabled = false,
    readOnly = false,
    className = '',
    options,
    placeholder = '',
    propertyValue = 'id',
    propertyLabel = 'name',
    style,
    ...rest
  }) => (
    <S.Container isBlock={isBlock} isInline={isInline} className={className || ''} style={style}>
      {label && <label htmlFor={id}>{label}</label>}
      <S.Group isInline={isInline} hasError={hasError} disabled={disabled} readOnly={readOnly}>
        <select
          id={id}
          required={required}
          aria-required={required}
          aria-disabled={disabled}
          disabled={disabled}
          {...rest}
        >
          {placeholder && <option value=''>{placeholder}</option>}
          {options &&
            options.length > 0 &&
            options.map((option) => (
              <option key={option[propertyValue]} value={option[propertyValue]}>
                {option[propertyLabel]}
              </option>
            ))}
        </select>
      </S.Group>
      {error && <S.Error>{error}</S.Error>}
    </S.Container>
  )
)

Select.displayName = 'Select'
