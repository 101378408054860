import React, { FC, memo } from 'react'
import * as S from './styles'
import { MultiTableProps } from './types'
import { CustomFiles, DesignServiceFiles, GraphicTicketFiles, OrderTable } from './TableStyle'

export const MultiTable: FC<MultiTableProps> = memo(({ tableType, page, searchItem, folderName, content }) => {
  if (tableType === 'myorder-files') {
    return <OrderTable tableType={tableType} page={page} searchItem={searchItem} content={content} />
  }
  if (tableType === 'graphic-ticket-files') {
    return <GraphicTicketFiles tableType={tableType} page={page} searchItem={searchItem} content={content} />
  }
  if (tableType === 'design-service-files') {
    return <DesignServiceFiles tableType={tableType} page={page} searchItem={searchItem} content={content} />
  }
  if (tableType === 'custom-files') {
    return (
      <CustomFiles
        tableType={tableType}
        page={page}
        searchItem={searchItem}
        currentFolderName={folderName}
        content={content}
      />
    )
  }

  return <></>
})

MultiTable.displayName = 'MultiTable'
