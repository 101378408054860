import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { argsInputMutate, argsCountryVatInputMutate } from '@/client/types/Vat'
import { getUserVatInputDisabled, getUserVatFromCountry } from '@/infra/services'

export const useGetUserVatInputDisabled = (obj) =>
  useQuery(['userVatEnabled'], () => getUserVatInputDisabled(obj), { refetchOnWindowFocus: false })

export const useMutateVatInputDisabled = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ vatInfo }: argsInputMutate) => getUserVatInputDisabled(vatInfo),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userVatInputEnabled'] })
    }
  })
}

export const useMutateGetVatNumber = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ vatInfoCountry }: argsCountryVatInputMutate) => getUserVatFromCountry(vatInfoCountry),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['userVatFromCountry'] })
    }
  })
}
