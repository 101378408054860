/*
 *
 * Loader
 *
 *
 * */
import { E_LOADER } from './constants'

export const ELReducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case E_LOADER.SET_LOAD:
      return {
        ...state,
        eload: payload.eload
      }

    case E_LOADER.SET_MESSAGE:
      return {
        ...state,
        elMessage: payload.elMessage
      }

    default:
      throw new Error('No case for that type')
  }
}
