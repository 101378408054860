import { useEffect, useState } from 'react'
import { Auth } from 'aws-amplify'
import { IUser } from '@/client/types'

export const useAuthUser = (): { user?: IUser; isAuthenticated: boolean } => {
  const [user, setUser] = useState<IUser>()

  useEffect(() => {
    Auth.currentAuthenticatedUser().then(({ attributes }) => {
      setUser({
        name: attributes.name,
        last_name: attributes.family_name,
        email: attributes.email,
        email_verified: attributes.email_verified,
        id: attributes['custom:customer_id']
      })
    })
  }, [])

  return {
    user,
    isAuthenticated: !!user
  }
}
