import styled, { css, keyframes, FlattenSimpleInterpolation } from 'styled-components'
import { ILoaderColor, ILoaderSize } from './types'
import color from '../theme/color'

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const hitZak = keyframes`
  0% {
    left: 0;
    transform: translateX(-1%);
  }
  100% {
    left: 100%;
    transform: translateX(-99%);
  }
`

export const PriceLoader = styled.span<{ color: ILoaderColor }>`
  display: block;
  position: relative;
  width: 100%;
  min-width: 40px;
  height: 3px;
  overflow: hidden;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 3px;
    background: ${(p) => color[p.color].pure};
    animation: ${hitZak} 0.6s ease-in-out infinite alternate;
  }
`

const rotation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const loaderSize: Record<ILoaderSize, FlattenSimpleInterpolation> = {
  small: css`
    width: 16px;
    height: 16px;
  `,
  medium: css`
    width: 32px;
    height: 32px;
  `,
  large: css`
    width: 48px;
    height: 48px;
  `
}

export const Spinner = styled.span<{ size: ILoaderSize; color: ILoaderColor }>`
  ${(p) => loaderSize[p.size]}
  border-radius: 50%;
  display: inline-block;
  border-top: 3px solid ${(p) => color[p.color].pure};
  border-right: 3px solid transparent;
  box-sizing: border-box;
  animation: ${rotation} 1s linear infinite;
`
