import styled, { css } from 'styled-components'
import { CheckboxStyleProps } from './types'
import borders from '../theme/borders'
import color from '../theme/color'
import space from '../theme/space'

export const CheckboxContainer = styled.label<{ boxTyle?: string; checked?: boolean }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  width: 100%;
  border: ${({ checked }) =>
    checked === false ? `2px solid ${color.grayscale.light}` : `2.5px solid ${color.grayscale.darker}`};
  padding: 1rem;
  border-radius: 1rem;
`

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`
/**** to be updated ***/
export const Shape = styled.span<CheckboxStyleProps>`
  position: relative;
  display: inline-block;
  height: 1.8rem;
  width: 1.8rem;
  margin-right: ${space.sm};
  border: ${borders.sizes.md};
  border-radius: 50%;
  user-select: none;
  cursor: pointer;
  transition: all 0.4s ease;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 1.1rem;
    width: 1.1rem;
    background-color: white;
    border-radius: 50%;
    border: ${borders.sizes.md} solid ${color.grayscale.black};
    box-shadow: 0 0 0 4px #e6f6ff;
    transition: all 0.4s ease;
  }

  ${(p) =>
    p.checked &&
    css`
      //border: ${borders.sizes.xl};
      &::before {
        background-color: ${color.primary.pure};
      }
    `}
`

const checkedStyled = css`
  width: ${space.xs};
  height: ${space.xs};
  opacity: 1;
`
const uncheckedStyled = css`
  width: 1.625rem;
  height: 1.625rem;
  opacity: 0;
`

export const InnerShape = styled.i<CheckboxStyleProps>`
  display: block;
  position: absolute;

  ${(p) =>
    p.kind === 'square' &&
    css`
      left: 7px;
      top: 1px;
      width: 10px;
      height: 15px;
      border-style: solid;
      border-color: ${color.grayscale.white};
      border-width: 0 ${borders.sizes.md} ${borders.sizes.md} 0;
      transform: rotate(45deg);
    `}

  ${(p) =>
    p.kind === 'circle' &&
    css`
      top: 50%;
      left: 50%;
      margin: auto;
      border-radius: 50%;
      transition: all 0.2s ease;
      transform: translate(-50%, -50%);
      width: 1.2rem !important;
      height: 1.2rem !important;
    `}
`

export const Infos = styled.span<{ displayType?: string }>`
  display: ${({ displayType }) => (displayType && displayType !== '' ? displayType : 'flex')};
  flex-wrap: wrap;
  margin-top: ${borders.sizes.sm};
  color: ${({ displayType }) => (displayType && displayType !== '' ? color.grayscale.darker : color.grayscale.black)};
`

export const RightEnd = styled.div`
  margin-left: auto;
`

export const IconSet = styled.div`
  margin-right: 10px;
  display: flex;
  align-items: center;
  & svg {
    margin-right: 10px;
  }
`
