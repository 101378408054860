import { FC, memo, useCallback, useEffect, useState } from 'react'
import { useScrollLock } from '@/client/hooks'
import { scrollTo } from '@/client/utils'
import { Mask } from './mask'
import * as S from './styles'
import { OnboardingProps } from './types'
import { Button } from '../Button'
import { Icon } from '../Icon'

export const Onboarding: FC<OnboardingProps> = memo(({ containerRef, beforeChange, finishTour, texts }) => {
  const [lockScroll, unlockScroll] = useScrollLock()
  const [updateMask, setUpdateMask] = useState<number>(0)
  const [step, setStep] = useState<number>(0)

  const { label, content } = texts.steps[step]
  const [position, setPosition] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    positionTop: 0,
    positionLeft: 0
  })

  useEffect(() => {
    lockScroll()

    return () => {
      unlockScroll()
    }
  }, [lockScroll, unlockScroll])

  const getOnboardingPosition = useCallback(({ top, left, width }) => {
    let positionTop = 0
    let positionLeft = 0

    if (top) {
      positionTop = top + document.documentElement.scrollTop
    }

    if (left && width) {
      positionLeft = left + width / 2
    }

    return {
      positionTop,
      positionLeft
    }
  }, [])

  const handlePosition = useCallback(
    (currStep: number) => {
      let initialPosition = { top: 0, left: 0, width: 0, height: 0 }
      const label = containerRef.current?.querySelector(texts.steps[currStep].selector as string)
      const labelPosition = label?.getBoundingClientRect() as DOMRect
      if (labelPosition) initialPosition = labelPosition
      const { top, left, width, height } = initialPosition
      const { positionTop, positionLeft } = getOnboardingPosition({ top, left, width })

      setPosition({
        top,
        left,
        width,
        height,
        positionTop,
        positionLeft
      })

      const offsetTop = top + document.documentElement.scrollTop - 400
      scrollTo(offsetTop, () => {
        setUpdateMask((oldState) => oldState + 1)
      })
    },
    [containerRef, getOnboardingPosition, texts.steps]
  )

  const handleNavigation = useCallback(
    (type) => {
      let currStep = step

      if (type === 'prev' && step > 0) {
        currStep = step - 1
      }

      if (type === 'next') {
        currStep = step + 1
      }

      if (currStep === texts.steps.length) {
        finishTour()
        return
      }

      beforeChange(step)
      setStep(currStep)
      handlePosition(currStep)
    },
    [beforeChange, step, texts.steps, finishTour, handlePosition]
  )

  return (
    <>
      <S.Onboarding top={position?.positionTop} left={position?.positionLeft}>
        <S.Close onClick={finishTour}>×</S.Close>
        {label && <S.Title>{label}</S.Title>}
        {content && <S.Paragraph dangerouslySetInnerHTML={{ __html: content }} />}

        <S.Navigation>
          {step === 0 && (
            <Button size='small' isOutlined onClick={finishTour}>
              {texts.navigation.later}
            </Button>
          )}

          {step > 0 && (
            <S.ArrowButton onClick={() => handleNavigation('prev')} aria-label='Go back'>
              <Icon name='arrow-left' />
            </S.ArrowButton>
          )}

          <S.Dots>
            {Array.from(Array(texts.steps.length).keys()).map((key) => (
              <S.Dot key={key} active={key === step} />
            ))}
          </S.Dots>

          {step === 0 && (
            <Button size='small' onClick={() => handleNavigation('next')}>
              {texts.navigation.start}
            </Button>
          )}

          {step > 0 && step < texts.steps.length - 1 && (
            <S.ArrowButton onClick={() => handleNavigation('next')} aria-label='Go further'>
              <Icon name='arrow-right' />
            </S.ArrowButton>
          )}

          {step === texts.steps.length - 1 && (
            <Button size='small' onClick={() => handleNavigation('next')}>
              {texts.navigation.finish}
            </Button>
          )}
        </S.Navigation>
      </S.Onboarding>

      <Mask
        update={updateMask}
        label={containerRef.current?.querySelector(texts.steps[step].selector as string) as HTMLElement}
      />
    </>
  )
})

Onboarding.displayName = 'Onboarding'
