import { Auth } from 'aws-amplify'
import { IVatResponse, ICountryVat } from '@/client/types'
import { protectedApi } from '@/client/utils'

export const getUserVatInputDisabled = async (vatInfo) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/activateVatInput/${attributes['custom:customer_id']}/${vatInfo.deliveryAddressId}/${vatInfo.countryId}`
  const { data } = await protectedApi.get<IVatResponse>(url)
  return data
}

export const getUserVatFromCountry = async (vatInfoCountry) => {
  const { attributes } = await Auth.currentAuthenticatedUser()
  const url = `/getCountryVatId/${attributes['custom:customer_id']}/${vatInfoCountry.country_id}`
  const { data } = await protectedApi.get<ICountryVat>(url)
  return data
}
