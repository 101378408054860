/*
 *
 * DS => Design Serive
 *
 *
 * */
import { createContext } from 'react'
import { useValues } from './LabelLibraryProvider'

export const LabelLibraryContext = createContext({} as ReturnType<typeof useValues>)
