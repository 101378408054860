import styled from 'styled-components'
import { tokens } from '../theme'
const { font } = tokens
import color from '../theme/color'

export const ImageNextErrorWrapper = styled.div`
  img {
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }
`

export const ImageNextWrapper = styled.div`
  img {
    max-width: 100%;
    width: auto !important;
    height: auto !important;
  }

  & #no-image-element {
    animation: loadingnoimage 1.2s ease infinite;

    @keyframes loadingnoimage {
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  }
`

export const ImagePreview = styled.div`
  position: absolute;
  top: 0;
  left: 100%;
  width: 400px;
  height: 400px;
  z-index: 99;
  transform: translateY(-50%);
  border: 1px solid ${color.grayscale.light};
  background: ${color.grayscale.light};
  & img {
    width: 100% !important;
    height: auto !important;
  }
`
