import styled from 'styled-components'
import RangeSlider from 'react-range-slider-input'
import 'react-range-slider-input/dist/style.css'
import space from '../theme/space'

export const RangeSliderManager = styled.div`
  margin: ${space.sm} 0;
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const TextAligner = styled.div`
  display: flex;
  justify-content: space-between;
`

export const RangeWrapper = styled.div`
  position: relative;
  display: flex;
  & .rangemanagerref {
    width: 100%;
  }
`

export const ContentWrapper = styled.div`
  position: absolute;
  z-index: 2;
`

export const SpanContents = styled.span<{ width: number; spanLength: number | undefined }>`
  display: inline-block;
  width: ${(p) => (p.spanLength ? p.width / p.spanLength : p.width)}px;
  color: #ffff;
  padding-left: 1rem;
`

export const RangeManager = styled(RangeSlider)`
  height: 23px !important;
  border-radius: 15px !important;
  background: #e1e1e1 !important;

  .range-slider__thumb {
    width: 40px !important;
    height: 40px !important;
    background: #00263e !important;
  }

  .range-slider__range {
    background: transparent !important;
  }

  .range-slider__thumb::before {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .range-slider__thumb[data-disabled] {
    display: none !important;
  }
`

export const SideLabel = styled.div`
  margin-left: 1rem;
`
