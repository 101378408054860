import { useMutation, useIsMutating } from '@tanstack/react-query'
import { StorageKey } from '@/client/types'
import { updateSampleBookOrder } from '@/infra/services/sampleBook'

export const useMutateSampleBook = () => {
  const isLoading = useIsMutating([StorageKey.SAMPLE_BOOK])

  const { mutate, isSuccess } = useMutation({
    mutationKey: [StorageKey.SAMPLE_BOOK],
    mutationFn: (data: {}) => updateSampleBookOrder(data)
  })

  return {
    mutate,
    isLoading: !!isLoading,
    isSuccess: !!isSuccess
  }
}
