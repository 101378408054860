import styled, { css } from 'styled-components'
import { DynamicKeys } from '@/client/types'
import { LinkStyleProps } from './types'
import { tokens } from '../theme'
const { color, font, space } = tokens

const getFixStyle = (currentColor, hoverColor) => css`
  color: ${currentColor};

  &:active,
  &:hover {
    color: ${hoverColor};
    opacity: 0.8;
    text-decoration: none;

    svg {
      fill: ${hoverColor};
    }
  }
`

const getUnderlinedStyle = (currentColor) => css`
  color: ${currentColor};

  &:active,
  &:hover {
    color: ${currentColor};
    text-decoration: underline;
  }
`

const getKind = {
  link: getFixStyle(color.text.normal, color.primary.pure),
  text: getFixStyle(color.text.weak, color.primary.pure),
  inverse: getFixStyle(color.text.inverse, color.primary.lighter),
  underlined: getUnderlinedStyle(color.text.weak)
}

const getSize: DynamicKeys = {
  default: '',
  xs: `font-size: ${font.size.xsmall};`,
  sm: `font-size: ${font.size.small};`,
  md: `font-size: ${font.size.root};`,
  lg: `font-size: ${font.size.h6};`
}

export const Link = styled.a<LinkStyleProps>`
  cursor: pointer;
  position: relative;
  transition: color 0.4s ease;
  ${(p) => getKind[p.kind]}
  ${(p) => getSize[p.size]}

  svg {
    margin-left: ${space.xs2};
    vertical-align: middle;
    transition: color 0.4s ease;
  }
`
