import { FC } from 'react'
import * as S from './styles'
import { ILoader } from './types'

export const ProgressLoader: FC<any> = ({ value, maxValue, width = 50, height = 50, noText = false, failed }) => {
  const progress = (value / maxValue) * 100
  const radius = Math.min(width, height) / 2 - 10
  const circumference = 2 * Math.PI * radius // Circumference of the circle
  const offset = circumference - (progress / 100) * circumference // Calculate the offset based on progress
  const strokeWidth = Math.min(width, height) / 15
  return (
    <S.Wrapper>
      <svg width={width} height={height}>
        <circle
          stroke={!failed ? `#e6e6e6` : `#ff0000`} // Background circle color
          fill='transparent'
          r={radius}
          cx={width / 2}
          cy={height / 2}
          strokeWidth={strokeWidth}
        />
        <circle
          stroke='#4caf50' // Progress color
          fill='transparent'
          r={radius}
          cx={width / 2}
          cy={height / 2}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          style={{ transition: 'stroke-dashoffset 0.5s ease-in-out' }}
        />
        {!noText && (
          <text
            x='50%'
            y='50%'
            textAnchor='middle'
            dominantBaseline='middle'
            fontSize={Math.min(width, height) / 5}
            fill='#000'
          >
            {value}/{maxValue}
          </text>
        )}
      </svg>
    </S.Wrapper>
  )
}
