export const defineInitialValues = (data, initialForm) => {
  const newInitialForm = { ...initialForm }
  if (!data) return newInitialForm

  for (const key of Object.keys(newInitialForm)) {
    if (data[key]) {
      newInitialForm[key].value = data[key]
      newInitialForm[key].dirty = true
      newInitialForm[key].hasError = false
    }
  }

  return newInitialForm
}
