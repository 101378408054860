import React, { FC, memo } from 'react'
import * as S from './styles'
import { InputProps } from './types'
import { Icon } from '../Icon'
import { Tooltip } from '../Tooltip'

export const Input: FC<InputProps> = memo(
  ({
    id,
    label,
    type = 'text',
    dimension = 'medium',
    placeholder,
    required,
    error,
    leftIcon,
    rightIcon,
    rightElement,
    isBlock,
    hasError,
    isPill,
    disabled,
    readOnly,
    className,
    style,
    register,
    description,
    maxLength,
    ...rest
  }) => (
    <S.Container isBlock={isBlock} className={className || ''} style={style}>
      {label && (
        <S.Label htmlFor={id} className='basic-input-element-label'>
          {label}
        </S.Label>
      )}
      <S.Wrapper
        dimension={dimension}
        isPill={isPill}
        hasError={hasError}
        disabled={disabled}
        readOnly={readOnly}
        leftIcon={Boolean(leftIcon)}
        rightIcon={Boolean(leftIcon) || Boolean(rightElement)}
        hasLabel={Boolean(label)}
        className='basic-input-element'
      >
        {leftIcon && <Icon name={leftIcon} />}

        <input
          type={type}
          id={id}
          required={required}
          aria-required={required}
          aria-label={label || placeholder}
          disabled={disabled}
          readOnly={readOnly}
          placeholder={placeholder}
          maxLength={maxLength}
          onWheel={(e) => e.currentTarget.blur()}
          {...rest}
          {...register}
        />

        {rightIcon && <Icon name={rightIcon} />}
        {description?.trim() && (
          <Tooltip title={description}>
            <S.Hint>
              <Icon name='info' color='#808891' />
            </S.Hint>
          </Tooltip>
        )}
        {rightElement}
      </S.Wrapper>
      {hasError && error && <S.Error>{error}</S.Error>}
    </S.Container>
  )
)

Input.displayName = 'Input'
