import React, { FC, memo } from 'react'
import Link from 'next/link'
import * as S from './styles'
import { CardBlogProps } from './types'
import { Image } from '..'
import { Button } from '../Button'
import { Tag } from '../Tag'

export const CardBlog: FC<CardBlogProps> = memo(
  ({ kind = 'default', href, image, tags = [], title, description, buttonLabel, priority, ...rest }) => (
    <Link href={href} passHref legacyBehavior>
      <S.Card kind={kind} {...rest}>
        <S.CardImage>
          <Image src={image.url} alt={image.alt} width={image.width} height={image.height} priority={priority} />
        </S.CardImage>

        <S.CardInfos>
          {tags.length > 0 && (
            <S.TagList>
              {tags.map(({ tag }) => (
                <Tag key={tag}>{tag}</Tag>
              ))}
            </S.TagList>
          )}
          <S.CardTitle size='lg' family='heading' weight='bold'>
            {title}
          </S.CardTitle>
          {description && (
            <S.CardSubtitle size='sm' color='weak' margin='0 0 1.5rem'>
              {description}
            </S.CardSubtitle>
          )}
          <Button isOutlined>{buttonLabel}</Button>
        </S.CardInfos>
      </S.Card>
    </Link>
  )
)

CardBlog.displayName = 'CardBlog'
