import styled, { css } from 'styled-components'
import { ContainerStyleProps, GroupStyleProps } from './types'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'

export const Container = styled.div<ContainerStyleProps>`
  position: relative;
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  color: ${color.grayscale.black};
  label {
    display: block;
    margin-bottom: ${space.xs};
    color: ${color.grayscale.dark};
    font-size: ${font.size.root};
    line-height: ${font.lineHeight.sm};
  }

  ${(p) =>
    p.isBlock &&
    css`
      display: block;
      width: 100%;
    `}

  ${(p) =>
    p.isInline &&
    css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
    `}
`

export const Group = styled.div<GroupStyleProps>`
  position: relative;
  background-color: ${color.grayscale.white};
  outline: ${borders.sizes.sm} solid ${color.grayscale.light};
  border-radius: ${borders.radius.lg};

  &:focus-within,
  &:focus {
    outline: ${borders.sizes.md} solid ${color.primary.darker};
  }

  & .selected-option {
    position: relative;
    display: inline-block;
    padding: 0.6rem 1rem;

    & .selected-ico {
      margin: 0 0.5rem 0 0;
    }
    &.selected-option-open {
      & .arrow-small-set {
        transform: translateY(-50%) rotate(180deg);
      }
    }
    & .arrow-small-set {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%) rotate(0);
    }
  }

  & .each-option {
    padding: 0.6rem 1.5rem;

    &.each-option-current {
      background: ${color.grayscale.light};
    }
    &:hover {
      background: ${color.grayscale.light};
    }
  }
  select {
    width: 100%;
    height: 4rem;
    padding-left: ${space.md};
    padding-right: ${space.md};
    background-color: transparent;
    border: 0;
    outline: 0;
    background-size: ${space.xs};
    font-size: ${font.size.root};
    color: ${color.text.normal};

    ::placeholder,
    ::-ms-input-placeholder,
    :-ms-input-placeholder {
      color: ${color.grayscale.dark};
    }
  }

  select:invalid {
    color: ${color.text.weak};
  }

  & .options {
    position: absolute;
    width: 120%;
    top: 2.5rem;
    left: 0;
    background: ${color.grayscale.white};
    box-shadow: rgba(16, 33, 51, 0.1) 0px 0px 30px;
    z-index: 9;
    & span {
      font-size: ${font.size.baseroot};
    }
  }

  ${(p) =>
    p.isInline &&
    css`
      margin-left: ${space.xs};
      margin-right: ${space.xs};
    `}

  ${(p) =>
    p.disabled &&
    css`
      color: ${color.grayscale.dark};
      opacity: 0.7;
    `}

  ${(p) =>
    p.readOnly &&
    css`
      background-color: ${color.warning.pale};
      color: ${color.grayscale.dark};
    `}

    ${(p) =>
    p.noBorder &&
    css`
      outline: none;
    `}
`

export const Error = styled.span`
  position: absolute;
  display: block;
  margin-top: ${space.xs2};
  margin-left: ${space.md};
  color: ${color.danger.dark};
  font-size: ${font.size.xsmall};
  line-height: ${font.lineHeight.sm};
`
