import { FC, memo } from 'react'
import { getWindow } from '@/client/utils/getWindow'
import * as S from './styles'

export const Mask: FC<{ update: number; label: HTMLElement }> = memo(({ label }) => {
  const position = label?.getBoundingClientRect()
  const { w, h } = getWindow()

  return (
    <S.Mask>
      <S.SvgWrapper width={w} height={h} xmlns='http://www.w3.org/2000/svg'>
        <defs>
          <mask id='tour__mask'>
            <rect x={0} y={0} width={w} height={h} fill='white' />
            <S.MaskArea
              x={position?.left}
              y={position?.top}
              width={position?.width}
              height={position?.height}
              fill='black'
              rx={0 ? 1 : undefined}
            />
          </mask>
        </defs>
        {position && <rect x={0} y={0} width={w} height={h} fill='currentColor' mask={`url(#tour__mask)`} />}
      </S.SvgWrapper>
    </S.Mask>
  )
})

Mask.displayName = 'Mask'
