import styled from 'styled-components'
import { WrapStyleProps } from './types'
import { tokens } from '../theme'
const { color, borders } = tokens

export const getSize: {
  [key: string]: number
} = {
  sm: 40,
  md: 48,
  lg: 64,
  xl: 96
}

export const Wrap = styled.span<WrapStyleProps>`
  display: block;
  height: ${(p) => getSize[p.size]}px;
  width: ${(p) => getSize[p.size]}px;
  border-radius: ${borders.radius.circle};
  overflow: hidden;
  ${({ hasBorder }) =>
    hasBorder &&
    `
    border: 3px solid ${color.grayscale.white};
  `}

  img {
    display: block;
    width: 100%;
    height: auto;
  }
`
