import styled, { css } from 'styled-components'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'

const getCheckedStyle = (checked: boolean) => {
  if (checked) {
    return css`
      background-color: ${color.grayscale.lighter};
      border: ${borders.sizes.md} solid ${color.primary.darker};
      padding: ${space.xl};

      button {
        color: ${color.primary.pure};
      }
    `
  }

  return css`
    background-color: ${color.grayscale.white};
    padding: calc(${space.xl} + 1px) calc(${space.xl} + 1px);
    border: ${borders.sizes.sm} solid ${color.grayscale.light};
  `
}

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
`

export const Actions = styled.div`
  display: flex;
  gap: ${space.md};
`

export const Button = styled.button`
  padding: 0;
  border: none;
  background-color: transparent;
  color: ${color.grayscale.dark};
  font-family: ${font.family.text};
  font-size: ${font.size.small};
  font-weight: ${font.weight.medium};
`

export const Card = styled.label<{ checked: boolean }>`
  border-radius: ${borders.radius.lg};
  cursor: pointer;
  transition: background-color 0.3s ease;
  ${({ checked }) => getCheckedStyle(checked)};
`
