import React, { FC, memo, useState } from 'react'
import * as S from './styles'
import { TextProps } from './types'
import { Icon, Text } from '@/client/components'

export const FormCol: FC<TextProps> = memo(({ header, description, noContent = false, children, ...rest }) => {
  const [showContent, setShowContent] = useState(true)
  const toggleContent = () => {
    setShowContent(!showContent)
  }
  return (
    <S.FormWrap>
      <S.ContentPart className='form-col-content-part'>
        <S.ContentHeader onClick={toggleContent}>
          <Text size='lg' margin='0 0 0 0'>
            {!noContent && header}
          </Text>
          {header.length !== 0 && description?.length !== 0 && !noContent && (
            <S.ExpandIcon isVisible={showContent}>
              <Icon name='chevron-down' size='s2' />
            </S.ExpandIcon>
          )}
        </S.ContentHeader>
        {showContent && (
          <S.ContentDescription>
            <Text as='span' color='weak' size='xs' italic>
              {description}
            </Text>
          </S.ContentDescription>
        )}
      </S.ContentPart>
      <S.InputPart inline className='input-part-set'>
        {children}
      </S.InputPart>
    </S.FormWrap>
  )
})

FormCol.displayName = 'FormCol'
