import { FC, useContext } from 'react'
import * as S from './styles'
import { ILoader } from './types'
import { Loader } from '../Loader'
import { Text } from '../Text'
import { ELoaderContext } from '@/client/context/Loader'

export const SiteLoader = ({ type = 'spinner', size = 'large', color = 'primary' }) => {
  const { elMessage, eload } = useContext(ELoaderContext)

  return (
    <S.Wrapper eLoad={eload}>
      <S.LoadSet>
        <Loader></Loader>
        <Text size='lg'>{elMessage}</Text>
      </S.LoadSet>
    </S.Wrapper>
  )
}
