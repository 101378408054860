import React, { memo, useRef, useEffect, FC } from 'react'
import * as S from './styles'
import { CollapseItemProps } from './types'
import { Icon } from '../Icon'

export const CollapseItem: FC<CollapseItemProps> = memo(
  ({ index = 0, kind = 'clean', isOpen = false, handleOpen = () => {}, title, children, ...rest }) => {
    const bodyRef = useRef<HTMLDivElement>(null)
    const childrenBodyRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
      if (!bodyRef?.current || !childrenBodyRef?.current) return

      if (isOpen) {
        const childrenHeight: number = childrenBodyRef.current.clientHeight
        bodyRef.current.style.overflow = 'initial'
        bodyRef.current.style.height = `${childrenHeight}px`
      } else {
        bodyRef.current.style.overflow = 'hidden'
        bodyRef.current.style.height = '0px'
      }
    }, [isOpen])

    return (
      <S.CollapseItem {...rest} kind={kind} open={isOpen}>
        <S.CollapseHeader className='collapse-header-ch' type='button' open={isOpen} onClick={() => handleOpen(index)}>
          {title}
          <Icon size='s5' name={isOpen ? 'minus-circle' : 'plus-circle'} />
        </S.CollapseHeader>

        <S.CollapseBody className='collapse-content-ch' ref={bodyRef} aria-hidden={!isOpen}>
          <S.CollapseBodyChildren className='collapse-children-ch' ref={childrenBodyRef}>
            {children}
          </S.CollapseBodyChildren>
        </S.CollapseBody>
      </S.CollapseItem>
    )
  }
)

CollapseItem.displayName = 'CollapseItem'
