import { useGetArtworkServices, useGetOrders } from '..'

export const useUploadCounter = (menu) => {
  const { totalUploads: totalUploadsOrders } = useGetOrders('current')
  const { totalUploads: totalUploadsArtwork } = useGetArtworkServices('current')

  menu.forEach((item) => {
    if (item.id === 'orders') item.uploadWarning = totalUploadsOrders
    if (item.id === 'artwork-services') item.uploadWarning = totalUploadsArtwork
  })

  return { menu }
}
