import { api } from '@/client/utils'

export const updateArtworkAssistant = async (data) => {
  const url = `/artwork-assistant/create`
  return await api.post(url, JSON.stringify(data))
}

export const getAiFile = async (height, width, label_shape, opaque, foil) => {
  const url = `/artwork/dieline-generator?height=${height}&width=${width}&shape=${label_shape}&addHPI=${opaque}&addFoil=${foil}&lang=es`
  return await api.get(url)
}
