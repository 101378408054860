import styled, { css, FlattenSimpleInterpolation } from 'styled-components'
import { ContainerStyleProps, WrapperStyleProps } from './types'
import borders from '../theme/borders'
import color from '../theme/color'
import font from '../theme/font'
import space from '../theme/space'

export const InputWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;

  & .label-circle {
    width: 23px;
    height: 23px;
    border-radius: 100px;
    position: absolute;
    left: 0;
    top: 0;
    background: ${color.primary.lightPale};
  }

  & input {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 2;
  }

  & input:checked + .label-circle:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    width: 10px;
    height: 10px;
    background: ${color.primary.dark};
    transform: translate(-50%, -50%);
    border-radius: 100px;
  }

  & label {
    padding-left: 35px;
  }
`
export const InputBox = styled.div``
export const PriceLabel = styled.div`
  & label {
    font-family: ${font.family.text};
    font-weight: ${font.weight.black};
  }
`
