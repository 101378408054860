import { useCallback } from 'react'
import { useRouter } from 'next/router'

export const useRouterPush = () => {
  const router = useRouter()

  const routerPush = useCallback(
    (path: string, query?: string) => {
      router.push({ pathname: path, query }, path, { locale: router.locale })
    },
    [router]
  )

  return {
    ...router,
    defaultLocale: router.defaultLocale || 'en',
    locale: router.locale || 'en',
    routerPush
  }
}
