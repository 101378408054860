import { useReducer, useCallback } from 'react'
import { ELoaderContext } from './ELoaderContext'
import { E_LOADER } from './constants'
import { ELReducer } from './reducer'
export const useValues = () => {
  /*
   *
   * Loader
   *
   *
   * */
  const [state, dispatch] = useReducer(ELReducer, {
    eload: false,
    elMessage: ''
  })

  const updateELoad = useCallback((payload) => {
    dispatch({
      type: E_LOADER.SET_LOAD,
      payload: {
        eload: payload
      }
    })
  }, [])

  const updateELoadMessage = useCallback((payload) => {
    dispatch({
      type: E_LOADER.SET_MESSAGE,
      payload: {
        elMessage: payload
      }
    })
  }, [])

  return {
    eload: state.eload,
    elMessage: state.elMessage,
    updateELoad,
    updateELoadMessage
  }
}

export const ELoaderProvider = ({ children }) => {
  const value = useValues()
  return <ELoaderContext.Provider value={value}>{children}</ELoaderContext.Provider>
}
