import { FC } from 'react'
import Image from 'next/image'
import * as S from './styles'
import { SelectCardProps } from './types'
import { Icon } from '../Icon'
import { Text } from '../Text'
import { Tooltip } from '../Tooltip'
import { Checkbox } from '../Checkbox'

export const SelectCard: FC<SelectCardProps> = ({
  id,
  backgroundType = 'default',
  orientation = 'horizontal',
  name,
  label,
  description,
  warning,
  value,
  checked,
  disabled,
  onChange,
  iconName,
  children,
  defaultValue,
  defaultChecked,
  register,
  textClassName,
  giveBackground,
  checkbox,
  ...rest
}) => (
  <S.SelectCard
    htmlFor={id}
    checked={checked ?? defaultChecked ?? false}
    disabled={disabled}
    orientation={orientation}
    backgroundType={backgroundType}
    className={checked ? 'checked-label' : ''}
    giveBackground={giveBackground}
    {...rest}
  >
    <S.HiddenInput
      id={id}
      name={register ? undefined : name}
      type='radio'
      value={value}
      checked={checked}
      disabled={disabled}
      onChange={register ? undefined : onChange}
      defaultValue={defaultValue}
      defaultChecked={defaultChecked}
      readOnly
      {...register}
    />

    {(iconName || label || description) && (
      <>
        {description?.trim() && orientation === 'vertical' && (
          <Tooltip title={description} className='topHint'>
            <S.Hint>
              <Icon name='info' color='#808891' />
            </S.Hint>
          </Tooltip>
        )}

        {!!iconName?.trim() && (
          <S.IconWrapper kind={'square'} checked={!!checked}>
            {checkbox && (
              <Checkbox
                kind='square'
                id={id}
                name={''}
                type='radio'
                value={'value'}
                checked={!!checked}
                onChange={() => onChange}
                label={''}
              />
            )}
            <Image src={`/icons/${iconName}.svg`} alt={iconName} fill className={iconName} />
          </S.IconWrapper>
        )}

        <S.Infos>
          {label && (
            <Text as='span' weight={checked ? 'medium' : 'normal'} margin='0 0.25rem 0 0' className={textClassName}>
              {label}
            </Text>
          )}
        </S.Infos>
        {warning && (
          <Tooltip title={warning}>
            <S.Hint>
              <Icon name='alert' color='#FF0000' />
            </S.Hint>
          </Tooltip>
        )}

        {description?.trim() && orientation === 'horizontal' && (
          <Tooltip title={description}>
            <S.Hint>
              <Icon name='info' color='#808891' />
            </S.Hint>
          </Tooltip>
        )}
      </>
    )}

    {children && <>{children}</>}
  </S.SelectCard>
)
