import { IPricesQuery } from '@/client/types'
import { api } from '@/client/utils'

export const getPrices = async (locale: string) => {
  const url = `/price/${locale}`
  const { data } = await api.get<IPricesQuery>(url)
  return data
}

export const updatePrices = async (locale: string, data) => {
  const url = `/price/${locale}`
  return await api.post<IPricesQuery>(url, data)
}
