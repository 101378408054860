import { FC, Fragment, memo, useCallback, useEffect, useState } from 'react'
import { IconName } from '@/client/components/Icon/types'
import { useRouterPush, useAuthUser } from '@/client/hooks'
import { ROUTER_PATH } from '@/client/utils'
import * as S from './styles'
import { CardPackageProps } from './types'
import { Button } from '../Button'
import { List } from '../List/List'
import { ListItem } from '../List/ListItem'
import { Text } from '../Text'
import { Icon } from '../Icon'

export const CardPackage: FC<CardPackageProps> = memo(
  ({
    version = 'old',
    kind = 'dark',
    name,
    description,
    list,
    price,
    prefix = '',
    suffix = '',
    observation,
    link,
    buttonLabel,
    image,
    index = '',
    redirectionRoute,
    ...rest
  }) => {
    const { routerPush, defaultLocale } = useRouterPush()
    const { isAuthenticated } = useAuthUser()
    const [activeCardIndex, setActiveCardIndex] = useState('')

    const handleMouseEnter = useCallback(
      (index) => {
        setActiveCardIndex(index == activeCardIndex ? '0' : index)
      },
      [index]
    )

    const handleMouseLeave = useCallback(
      (index) => {
        index === '0' && setActiveCardIndex('1')
        index === '1' && setActiveCardIndex('0')
      },
      [index]
    )

    const handleAuthorization = useCallback(() => {
      if (isAuthenticated) return routerPush(link)
      routerPush(`${ROUTER_PATH.LOGIN[defaultLocale]}?redirect=${redirectionRoute}`)
    }, [link, isAuthenticated, routerPush, defaultLocale])

    return (
      <S.Card
        version={version}
        kind={kind}
        isActive={activeCardIndex == index}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseLeave={() => setActiveCardIndex('0')}
        {...rest}
      >
        <>
          <S.CardHeader kind={kind} version={version}>
            {name}
          </S.CardHeader>

          <S.CardInfos version={version}>
            {description && (
              <S.Description>
                <S.Description>
                  {image?.map(({ key, icon }) => (
                    <Fragment key={key}>
                      <Icon name={icon} size='s7' color='black' />
                    </Fragment>
                  ))}
                </S.Description>

                <Text
                  margin={version === 'new' ? '0 0 2rem 1.5rem' : '0 0 1.5rem'}
                  weight='bold'
                  lineHeight='1.2'
                  size='xs'
                >
                  <span dangerouslySetInnerHTML={{ __html: description }} />
                </Text>
              </S.Description>
            )}

            <List kind='gold'>
              {list.map(({ key, icon, text, isBold, fontSize }) => (
                <Fragment key={key}>
                  <ListItem
                    key={text}
                    iconName={icon as IconName}
                    weight={isBold ? 'medium' : 'normal'}
                    fontSize='small'
                  >
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                  </ListItem>
                </Fragment>
              ))}
            </List>
          </S.CardInfos>
        </>

        <S.CardFooter version={version}>
          <Text color='weak' margin={!!observation ? '0 0 0.5rem' : '0 0 2rem'}>
            {`${prefix} `}
            <Text as='span' size='h3' weight='semiBlack' margin='0' padding={version === 'new' ? '0 0 0 2rem' : '0'}>
              {price}
            </Text>
            {` ${suffix}`}
          </Text>

          {observation && version === 'old' && (
            <Text size='sm' align='center' margin='0 0 2rem' padding='0 1.5rem'>
              <span dangerouslySetInnerHTML={{ __html: observation }} />
            </Text>
          )}

          {observation && version === 'new' && (
            <Text margin='0 0 0.5rem' padding='0 0.5rem 0 2rem' lineHeight='1.2' color='black'>
              <span dangerouslySetInnerHTML={{ __html: observation }} className='newObervationText' />
            </Text>
          )}

          <S.ButtonWrapper version={version} kind={kind}>
            {version === 'old' && (
              <Button isOutlined kind='dark' onClick={handleAuthorization}>
                {buttonLabel}
              </Button>
            )}
            {version === 'new' && (
              <Button onClick={handleAuthorization} className='buttonNewVersion'>
                <Text color='inverse' weight='semiBlack' size='lg' margin='0.1rem 0 0'>
                  {buttonLabel}
                </Text>
                <Icon name='arrow-right' size='s9' />
              </Button>
            )}
          </S.ButtonWrapper>
        </S.CardFooter>
      </S.Card>
    )
  }
)

CardPackage.displayName = 'CardPackage'
