import React, { FC, memo, useState, useCallback, Children, isValidElement, cloneElement } from 'react'
import * as S from './styles'
import { CollapseProps } from './types'

export const Collapse: FC<CollapseProps> = memo(({ kind = 'clean', children, ...rest }) => {
  let renderedChildren = children
  const [openChildren, setOpenChildren] = useState()

  const handleOpen = useCallback(
    (index) => {
      let newIndex = index

      if (openChildren === index) {
        newIndex = null
      }

      setOpenChildren(newIndex)
    },
    [openChildren]
  )

  renderedChildren = Children.map(children, (child, index) => {
    if (isValidElement(child)) {
      const newProps = { index, kind, handleOpen, isOpen: openChildren === index }
      return cloneElement(child, newProps)
    }
    return child
  })

  return <S.Collapse {...rest}>{renderedChildren}</S.Collapse>
})

Collapse.displayName = 'Collapse'
